import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import Wait from '@react/react-spectrum/Wait';
import { Tag } from '@react/react-spectrum/TagList';
import ShareIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_Share_18_N.svg';
import UnShareIcon from 'dc-icons/contextboard/s_unshare_18.svg';
import ActionButton from 'common/actionButton';
import { STARTUP_ACTIONS, MAX_SHAREE_MESSAGE_LENGTH, CONTEXT_BOARD_TYPES } from 'stores/constants';
import InputEmail from 'common/inputEmail';
import TextAreaX from 'common/textAreaWithMaxLen';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';
import UnshareComponent from './unshare';

const MAX_SHAREES_FOR_UNLIMITED_SETTING = 20;

const analytics = analyticsFor(analyticsFor.SHARE_AGREEMENT);
const analyticsUnshare = analyticsFor(analyticsFor.UNSHARE_AGREEMENT);

const ShareeEmailsTagList = styled.div`
  margin-bottom: 10px;
  max-width: inherit;
  max-height: 220px;
  overflow-y: auto;

  @media only screen and (max-width: 480px) {
    max-width: 220px;
  }

  /* fix for IE11 - DCES-4249961 */
  .spectrum-ClearButton {
    .spectrum-Icon {
      margin: -5px;
    }
  }
`;

const StyledShareDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }
    overflow-y: hidden;
    max-width: 550px;
    min-height: 400px;
  }
`;

const UnShareButton = styled(ActionButton)`
  && {
    background-color: #f6f2f2;
    border-color: #e1e1e1;
    color: #4b4b4b;
    max-width: 550px;
  }
`;

/**
 * Get the sharees list to be be displayed in the Unshareagreement modal
 * If Originator, then they will be able to see the first level sharees from other particpants in the agreement
 * For all other users, the Unshare agreement modal will only display their direct sharees with hierarchical model
 */
const getSharees = (shares, isOriginator) => {
  if (isOriginator) {
    /**
     * This logic is required to fetch the first level sharees from all the participants of the agreement
     * When sharee's sharerParticipantId is not part of particpantId in other sharees, then it means that sharee is in first level of shares.
     * Either originator or participants of the agreement would have shared the agreement to sharee. Not by other sharees.
     * If sharee's sharer in the same list, then its not first level
     * The sharee count gets updated due to 1. Unshare action from the Unshare dialog. 2. Replace Signer scenario.
     * So, passing the sharesObservable to get the sharees list
     */
    return shares.shareParticipantInfo.filter(sharee => {
      const sharerList = shares.shareParticipantInfo.filter(
        hierarchyShare => sharee.sharerParticipantId === hierarchyShare.participantId
      );
      return sharerList.length < 1;
    });
  } else {
    return shares.mySharesInfo;
  }
};

@inject('agreement', 'stores', 'eventful')
@observer
class ShareDialog extends Component {
  @observable
  isMessageInvalid = null;

  @observable
  shareeEmailsList = [];

  @observable
  maxShareesReached = null;

  @observable
  loading = false;

  @observable
  showUnshare = false;

  @action
  setLoading(val) {
    this.loading = val;
  }

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.agreementType = props.type;
    this.shareeMessage = '';
    this.members = this.props.agreement.members;
    // This is required to isolate Megasign and Webforms and implement it only for Esign agreement
    this.isTypeAgreement = this.props.type === CONTEXT_BOARD_TYPES.AGREEMENT;
    if (this.isTypeAgreement) {
      this.shares = this.props.agreement.shares;
      // Checking if the feature flipper is turned for Unshare Agreement to hide/show Unshare feature from the UI
      this.canAgreementBeUnshared = props.stores.UserSettings.canAgreementBeUnshared();
      this.showUnshare = this.canAgreementBeUnshared && this.props.sharesCount > 0;
    }

    this.maxSharees = this.props.stores.UserSettings.canShareWithNoLimit()
      ? MAX_SHAREES_FOR_UNLIMITED_SETTING
      : this.props.stores.UserSettings.maxShareesLimit();
  }

  /**
   * Dedupe and Add email(s) to tag list
   * @param emails {string|array} validated email(s) to add
   */
  @action
  addEmails(emails) {
    if (!emails.forEach) emails = [emails];
    emails.some((email, i) => {
      this.shareeEmailsList.push(
        <Tag key={email} value={email} onClose={val => this.onDeleteTag(val)} closable>
          {email}
        </Tag>
      );

      // stop if reached max sharees
      if (this.atMaxSharees) {
        this.maxShareesReached = true;
        return true; // stop the iterator
      }
      return null;
    });
    return this.atMaxSharees || emails.length <= 1; // clears email from input email field
  }

  /**
   * Handler to update the sharees list when deleted from the tag list
   * @param val {string} - value of the tag that needs to be removed
   */
  @action
  onDeleteTag(val) {
    this.shareeEmailsList = this.shareeEmailsList.filter(
      shareeEmail => shareeEmail.props.value !== val
    );
    this.maxShareesReached = false;

    // fire update for input emails component
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'share',
        target: 'InputEmail',
        type: 'delete',
        email: val
      });
    }
  }

  /**
   * Handler to update the shareeMessage on key up
   * @param e {Event}
   */
  @action
  onMessageChange(msg, { isInvalid }) {
    this.shareeMessage = msg;
    this.isMessageInvalid = isInvalid;
  }

  get atMaxSharees() {
    return this.shareeEmailsList.length === this.maxSharees;
  }

  /**
   * Handler for when user clicks on unshare this agreement.
   */
  @action
  openUnshareModalDialog() {
    const container = window.document.body;
    analyticsUnshare.clicked('open_modal');
    // Close the Share dialog
    if (this.dialogRef.current) {
      this.dialogRef.current.props.onClose();
    }
    // Open Unshare dialog
    ModalContainer.show(
      <UnshareComponent
        {...this.props}
        originator={this.members.agreement.isSender || false}
        getSharees={getSharees}
      />,
      this,
      container
    );
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._string = this._strings || {
      closePopoverLabel: formatMessage({ id: 'common.close' }),
      copyEmailsMessage: formatMessage({ id: 'share.copy_emails_message' }),
      maxShareesMessage: formatMessage(
        { id: 'share.max_sharees_reached_message' },
        { max_sharees: this.maxSharees }
      ),
      messagePlaceHolder: formatMessage({ id: 'share.message_placeholder' }),
      shareLabel: formatMessage({ id: 'share.share_button' }),
      sharePopoverTitle: formatMessage({ id: 'share.header.' + this.agreementType }),
      shareSubText: formatMessage({ id: 'share.sub_text.' + this.agreementType }),
      unsharePopoverTitle: formatMessage({ id: 'share.unshare.' + this.agreementType }),
      unshareAgreementButton: formatMessage({ id: 'share.unshare_agreement_button' })
    });
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'share',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  render() {
    const t = this.strings,
      doneProps = this.maxShareesReached
        ? {
            placeholder: t.maxShareesMessage,
            validationState: 'valid',
            value: '' // reset input field
          }
        : null,
      { showToast, onClose } = this.props;

    return (
      <StyledShareDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={t.closePopoverLabel}
        onConfirm={() => this.shareAgreement()}
        confirmLabel={t.shareLabel}
        confirmDisabled={this.isMessageInvalid || this.loading || !this.shareeEmailsList.length}
        ref={this.dialogRef}
        title={t.sharePopoverTitle}
        showToast={showToast}
        onClose={onClose}
      >
        {this.showUnshare && (
          <ActionSection>
            <UnShareButton
              id="unshare.action_button"
              label={t.unshareAgreementButton}
              onClickHandler={() => this.openUnshareModalDialog()}
              icon={<UnShareIcon />}
              disabled={this.loading}
            />
          </ActionSection>
        )}
        {this.loading && <Wait centered />}
        <p>{t.shareSubText}</p>
        <InputEmail
          multiple
          {...doneProps}
          readOnly={this.maxShareesReached}
          className="sharee-email-field"
          allowDuplicate={false}
          onComplete={emails => this.addEmails(emails)}
          eventful={this.props.eventful}
        />
        <p className="spectrum-Body--small">{t.copyEmailsMessage}</p>
        <ShareeEmailsTagList>{this.shareeEmailsList}</ShareeEmailsTagList>
        <TextAreaX
          className="sharee_message"
          disabled={this.loading}
          height="90px"
          onChange={this.onMessageChange.bind(this)}
          placeholder={t.messagePlaceHolder}
          maxLength={MAX_SHAREE_MESSAGE_LENGTH}
        />
      </StyledShareDialog>
    );
  }

  /**
   * Handler for when user confirms to sharing the agreement.
   */
  shareAgreement() {
    let shareCreationInfo = [];
    this.shareeEmailsList.forEach(shareeEmailTag => {
      shareCreationInfo.push({
        email: shareeEmailTag.props.value,
        message: this.shareeMessage
      });
    });

    // remember original number of sharees
    const numSharees = this.members.get('sharesInfo').length;

    this.setLoading(true);
    let sharesCollection = this.members.share;
    sharesCollection.set(shareCreationInfo);

    analytics.setContext({
      share: {
        count: sharesCollection.size(),
        note: this.shareeMessage.length
      }
    });

    // TESTING
    // this.members.set('sharesInfo', sharesCollection);
    // return;

    sharesCollection
      .save()
      .then(async () => {
        this.setLoading(false);
        const { formatMessage } = this.props.stores.Intl;
        analytics.success();

        // fetch members to get dedupes, names (if available) and update observable
        const promises = [];
        promises.push(this.members.fetch());
        if (this.isTypeAgreement && this.canAgreementBeUnshared) {
          promises.push(this.shares.fetch());
        }
        await Promise.all(promises);
        /**
         * was anyone added?
         * This is existing code in Prod. If no one is added simply return with a toast
         * This does not call modal close.
         */
        if (this.members.get('sharesInfo').length === numSharees) {
          this.props.showToast({
            text: formatMessage({ id: 'share.already_shared.' + this.agreementType }),
            type: 'info'
          });
          return;
        }
        // one or more sharees were added (we don't know which ones were rejected!)
        const firstShareeEmail = sharesCollection.at(0).get('email');
        let successText = '';
        if (sharesCollection.models.length > 1) {
          successText = formatMessage(
            { id: 'share.success_message_multiple.' + this.agreementType },
            { length: sharesCollection.size() - 1, name_or_email: firstShareeEmail }
          );
        } else {
          successText = formatMessage(
            { id: 'share.success_message_single.' + this.agreementType },
            { name_or_email: firstShareeEmail }
          );
        }
        this.props.showToast({
          text: successText,
          type: 'success'
        });

        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(this.transformError(error));
      });

    return false;
  }

  /**
   * Handles a special case where an {emails} keyword is present in the localized string.
   * (If this functionality is needed for other error strings used by this class, a more generic version of this
   * function should be created.)
   * @param {{origMessage: string, message: string, code: string}} error
   * @return {Object} the error object, with instances of the keyword replaced for objects with the specific error code.
   */
  transformError(error) {
    let resultError = { ...error };
    if (
      error.code === 'AGREEMENT_SHARING_NO_PERMISSION' &&
      error.origMessage &&
      error.origMessage.includes('[') &&
      error.origMessage.includes(']')
    ) {
      const emailString = error.origMessage.substring(
        error.origMessage.indexOf('['),
        error.origMessage.indexOf(']') + 1
      );
      resultError.message = this.props.stores.Api.Utils.i18nReplace(error.message, {
        emails: emailString
      });
    }
    return resultError;
  }

  onSuccess() {
    this.resetValues();
    this.dialogRef.current.props.onClose();
  }

  /**
   * clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.isMessageInvalid = false;
    this.shareeEmailsList = [];
    this.maxShareesReached = false;
  }
}

@inject('agreement', 'stores')
@observer
class Share extends Component {
  constructor(props) {
    super(props);
    this.members = this.props.agreement.members;
    this.observable = this.props.stores.getObservableModel(this.members);
    this.isTypeAgreement = this.props.type === CONTEXT_BOARD_TYPES.AGREEMENT;
    /**
     * The shares count on the Manage page actions
     * Share/Unshare changes depending on the direct sharees count
     */
    if (this.isTypeAgreement) {
      this.shares = this.props.agreement.shares;
      this.sharesObservable = this.props.stores.getObservableModel(this.shares);
      this.shareeEmailList = [];
      this.canAgreementBeUnshared = this.props.stores.UserSettings.canAgreementBeUnshared();
    }
  }

  componentDidMount() {
    if (this.isTypeAgreement && this.canAgreementBeUnshared) {
      this.shares.fetch();
    }
  }

  render() {
    const { formatMessage } = this.props.intl,
      container = window.document.body;

    let shareUnshareTitle = 'share.title',
      sharesCount = 0;

    // Get shares count
    if (this.isTypeAgreement && this.canAgreementBeUnshared) {
      const isOriginator = this.members.agreement.isSender;
      this.shareeEmailList = getSharees(this.sharesObservable, isOriginator);
    } else {
      this.shareeEmailList = this.observable.sharesInfo;
    }

    // Update label
    if (this.canAgreementBeUnshared && this.shareeEmailList.length > 0) {
      shareUnshareTitle = 'share.unshare.title';
    }

    let label = formatMessage(
      {
        id: shareUnshareTitle
      },
      {
        type: this.props.type
      }
    );

    label += this.shareeEmailList.length ? ` (${this.shareeEmailList.length})` : '';
    sharesCount = this.shareeEmailList.length;

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            label={label}
            icon={label && <ShareIcon />}
            className={classNames.SHARE_AGREEMENT_SECTION}
            analytics={analytics}
            openComponent={this.props.startupAction === STARTUP_ACTIONS.SHARE}
            openComponentDelay={this.props.startupActionDelay}
          />
        </ActionSection>
        <ShareDialog {...this.props} sharesCount={sharesCount} />
      </ModalTrigger>
    );
  }
}

const ShareView = WithToastMessage(Share);

export default props =>
  !props.hasDocRetention && props.stores.Env.loggedIn && props.stores.UserSettings.canShare() ? (
    <ShareView {...props} />
  ) : null;
