import stores from 'stores';
import logger from 'utils/logger';
import { analyticsFor } from 'utils/analytics';

const FETCH_SETTINGS = ['unresolvedBounceIndicatorFeatureAvailable', 'liveFormFieldsDataEnabled'];

const analytics = analyticsFor(analyticsFor.GROUP_SETTINGS_SEARCH);

/**
 * Settings for user group: /user/me/groups/<groupId>/settings/search
 *
 */
export default class GroupSettingsSearch {
  constructor(Api, groupId) {
    // setting default values
    this.me = new stores.Api.Users.User({ id: 'me' });
    this.groupId = groupId;
    this.settings = new stores.Api.Users.Group({ id: this.groupId }, { user: this.me }).search;
    this.promise = null;
    this.rejected = false;
    this.headers = {};
    /**
     * If the agreement is shared, then it is necessary to add the sharer user Id in 'x-on-behalf-of-user' of the header
     * And this is only for when the user selects a sharee from dropdown on the manage page.
     * Not required for switch mode (it is automatically added).
     */
    if (stores.accountSharing.shouldShowBanner()) {
      // The senderInfo is used for regualr agreements, for otherwise creatorInfo (for example MegaSign).
      const originator =
        stores.agreement.members.get('senderInfo') || stores.agreement.members.get('creatorInfo');
      const sharerUserId = originator.get('userId');
      if (sharerUserId) {
        this.headers = { 'x-on-behalf-of-user': `userid:${sharerUserId}` };
      }
    }
  }

  fetch() {
    const hasSettingsSearchApiEnabled = stores.Floodgate.hasSettingsSearchApiEnabled();
    // TODO: Remove this condition once floodgate flag dependency is eliminated. If not this feature will not be enabled in GovCloud for 15.1
    if (!hasSettingsSearchApiEnabled) {
      return Promise.resolve(this);
    }
    this.promise = this.settings
      .save({ settings: FETCH_SETTINGS }, { headers: this.headers })
      .then(res => {
        return this; // resolved value
      })
      .catch(e => {
        // the failure is caught and not thrown again, so the context board may render
        // even in this scenario.
        this.rejected = true;
        logger.warn('Unable to get user settings search', { error: e });
        analytics.failed(e);
        // TODO: Throw an error here when this API is available in monolith after 15.1 release
      });
    return this.promise;
  }

  isLiveFormFieldDataFeatureEnabled() {
    return this.settings.list.getByName('liveFormFieldsDataEnabled');
  }

  /*
   * Checks whether unresolvedBounceIndicatorFeatureAvailable setting is true or false; if it does not exist
   * default to return false
   * Returns: bool
   */
  isUnresolvedBounceIndicatorFeatureAvailable() {
    return this.settings.list.getByName('unresolvedBounceIndicatorFeatureAvailable');
  }
}
