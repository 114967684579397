import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { action, computed } from 'mobx';
import { isEmpty } from 'lodash/core';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { CONTEXT_BOARD_TYPES, REPLACE_FLAVORS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import { WithToastMessage } from 'as-ducati-core';
import { AgreementMessage } from 'components/summary-info';
import PopoverX from 'common/popover-x';
import theme from 'common/theme';
import OverlayTriggerX from 'common/overlayTriggerX';
import {
  LinkStyledButton,
  DoneCheckmark,
  OverflowEllipsisHeaderText,
  AutoSavedDataIcon,
  LockIcon
} from 'common/styledElements';
import {
  KEYS,
  unfinishedRecipientHasBounceEvent,
  isSenderViewOfInFlightAgreement
} from 'utils/helper';
import BouncedIcon from 'common/bouncedIcon';
import ReplaceSignerView from './replaceSignerView';
import * as classNames from './classNames';
import * as icons from './icons.js';

import EditAuthentication from './authentication/editAuthentication';
import Group from './group';
import EditRecipientName from './editRecipientName';
import EditPrivateMessageView from './editPrivateMessageView';
import LiveFormDataView from './liveFormDataView';

const TRANSITION_ANIMATION = theme.animate.fadeInLeft;
const ICON_KEYS = Object.keys(icons);

let Api, MEMBER_STATUS, MEMBER_DONE_STATUSES;
const analytics = analyticsFor(analyticsFor.MEMBERS);

// in these cases, we don't show the minimized list, nor the count
const RANDOM_MEMBER_COLORS = [
  '#00D8AF',
  '#00CFFF',
  '#5D99FF',
  '#9B80FF',
  '#FFCD00',
  '#F98E00',
  '#FF5AE0'
];

const MembersPopover = styled(PopoverX)`
  &&& {
    margin-top: -10px;
    width: ${props => (props.width ? props.width + 'px' : '420px')};

    @media only screen and (max-width: 480px) {
      width: 300px;
    }

    word-wrap: break-word;
  }

  .replace_link {
    margin: 1px 10px 0 1px;
  }
`;
const OverflowEllipsisUnknownParticipant = styled.span`
  display: inline-block;
  max-width: 55%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OverflowEllipsisWithCounterSignerLabel = styled.span`
  display: inline-block;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  height: 20px;
  max-width: 20px;
  margin-right: 10px;
  display: inline-block;

  & circle {
    fill: ${props => props.fill};
  }
`;

const MemberListItem = styled.li`
  list-style-type: none;
  display: flex;
  min-height: 28px;
  cursor: pointer;
  margin-bottom: 2px;
  && {
    animation-duration: ${theme.global_animation_duration_200};
  }

  /* this is the connecting line between the participants
  &:not(:last-child)&:after {
    width: ${props => (props.workflow === 'SEQUENTIAL' ? '2px' : '0px')};
    margin: 19px 0 0 8px;
    display: flex;
    height: ${props => (props.height ? props.height - 20 + 'px' : '16px')};
    content: '';
    background: ${theme.global_color_gray_500};
    position: absolute;
  }*/
`;

MemberListItem.displayName = 'Member';

const MemberListItemInformation = styled.div`
  margin: -2px 0px 5px -1px
  max-width: 80%;
;
`;

const MemberListItemInformationFullWidth = styled.div`
  margin: -2px 0px 5px -1px
  max-width: 100%;
;
`;

// in popover on member hover
const MemberInfo = styled.div`
  margin: 5px 0;
`;

const RoleText = styled.div`
  font-size: ${theme.global_font_size_small};
  margin-top: -2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .spectrum--light & {
    color: ${theme.global_color_gray_700};
  }

  .spectrum--dark & {
    color: #909090;
  }
`;

MemberInfo.displayName = 'MemberInfo';

const VIEWS = {
  ADD_ALTERNATE: REPLACE_FLAVORS.ADD_ALTERNATE_PARTICIPANT,
  DEFAULT: '',
  EDIT_AUTH: 'edit_authentication',
  EDIT_RECIPIENT_NAME: 'edit_recipient_name',
  EDIT_PRIVATE_MESSAGE: 'edit_private_message',
  REPLACE: REPLACE_FLAVORS.REPLACE_PARTICIPANT
};

const DIG_AUTH = 'DIG_ID';

const CLICK_EVENT = {
  [VIEWS.EDIT_AUTH]: VIEWS.EDIT_AUTH,
  [VIEWS.EDIT_RECIPIENT_NAME]: VIEWS.EDIT_RECIPIENT_NAME,
  [VIEWS.EDIT_PRIVATE_MESSAGE]: VIEWS.EDIT_PRIVATE_MESSAGE,
  [VIEWS.ADD_ALTERNATE]: VIEWS.ADD_ALTERNATE,
  [VIEWS.REPLACE]: VIEWS.REPLACE
};

const getFill = () => RANDOM_MEMBER_COLORS[Math.floor(RANDOM_MEMBER_COLORS.length * Math.random())];

const RandomIcon = () => {
  let Icon = icons[ICON_KEYS[Math.floor(Math.random() * ICON_KEYS.length)]];
  return (
    <IconWrapper fill={getFill()}>
      <Icon />
    </IconWrapper>
  );
};

const isWidget = type => type === CONTEXT_BOARD_TYPES.WIDGET;
const isWebForm = type => type === CONTEXT_BOARD_TYPES.WEBFORM;

const MemberListInformationMultiSignerWebForm = props => {
  const { formatMessage } = props.stores.Intl,
    counterSignerLabel = '(' + formatMessage({ id: 'widget.countersigners_singular' }) + ')',
    unknownSignerName = formatMessage({ id: 'widget.participant_unknownName' }),
    isSequential = props.workflow === 'SEQUENTIAL';
  // nameToShow = props.nameToShow;
  const { nameToShow, isGroup } = props;
  let pdsRequired = false;
  let roleOrEventText = props.roleOrEventText;
  const unknownParticipantInfo = props.participantSet.get('providerParticipantSetInfo');

  // MSWF - differentiate counter signers(by adding counter signer with their names displayed) from unknown participants
  let isCounterSigner = nameToShow && nameToShow !== unknownSignerName && !props.isCC;
  // Show name(or email) info with numbering
  let nameInfo;
  if (props.isCC) {
    nameInfo = nameToShow;
  } else {
    nameInfo =
      (isSequential && !props.isGroupMember ? props.memberIndex + 1 + ') ' : '') +
      Api.Utils.trimSmsDomain(nameToShow);
  }

  // Show (Required) or (Optional) for an unknown participant along with role
  if (unknownParticipantInfo) {
    pdsRequired = unknownParticipantInfo.actionRequired
      ? formatMessage({ id: 'required' })
      : formatMessage({ id: 'optional' });
    roleOrEventText = roleOrEventText + ' (' + pdsRequired + ')';
  }
  return (
    <MemberListItemInformationFullWidth>
      {/* DCES-4284667 Wrap Name instead of Counter Signer Label (shown in brackets) if length exceeds maximum width for Multi Signer Web Form Feature Enabled Users*/}
      {isCounterSigner ? (
        <div className={classNames.MEMBER_NAME}>
          <OverflowEllipsisUnknownParticipant>{nameInfo}</OverflowEllipsisUnknownParticipant>
          <OverflowEllipsisWithCounterSignerLabel>
            {counterSignerLabel}
          </OverflowEllipsisWithCounterSignerLabel>
        </div>
      ) : (
        <OverflowEllipsisHeaderText className={classNames.MEMBER_NAME}>
          {nameInfo}
        </OverflowEllipsisHeaderText>
      )}
      {!isGroup && (
        <RoleText data-automation-id="participant-event-text" title={roleOrEventText}>
          {roleOrEventText}
        </RoleText>
      )}
    </MemberListItemInformationFullWidth>
  );
};

const EditRecipientNameLinkView = props => {
  if (!props.isEditable) {
    return null;
  }

  const { formatMessage } = props.stores.Intl;
  return (
    <LinkStyledButton
      quiet
      variant="action"
      className={'edit_recipient_name'}
      onClick={props.onClick}
    >
      {formatMessage({ id: 'actions.edit' })}
    </LinkStyledButton>
  );
};

/**
 * Returns the content which should be displayed in the subtitle Section of Members Popover
 * We will add PhoneNumber after formatting it properly (if it was given while creating the agreement as delivery option), below the existing Subtitle
 * phoneDeliveryInfo is not enabled for widgets
 * @returns {*|string}
 */
const getSubTitle = (member, isWidget, isSender) => {
  let subTitle = member.get('name') && member.get('email');
  const phoneNumber = member.get('phoneDeliveryInfo');

  if (isWidget || !phoneNumber || phoneNumber.isEmpty()) {
    if (subTitle && subTitle.includes(Api.Utils.SMS_SUPU_EMAIL_DOMAIN)) {
      subTitle = subTitle.replace(Api.Utils.SMS_SUPU_EMAIL_DOMAIN, '');
    }
    return subTitle;
  }

  const displayPhoneNumber = isSender
    ? phoneNumber.getFormattedString()
    : phoneNumber.getMaskedPhoneNumber();
  if (subTitle) {
    if (!subTitle.includes(Api.Utils.SMS_SUPU_EMAIL_DOMAIN)) {
      return subTitle + '<br>' + displayPhoneNumber;
    }
  }

  return displayPhoneNumber;
};

/**
 * Returns an Element containing Agreement Delivery info, ie Agreement was delivered to Email and SMS
 * this is added if phone number is present.
 * phoneDeliveryInfo is not enabled for widgets
 * Returns null if no phone info present
 * @returns {JSX.Element}
 */
const DeliveryMessageView = ({ member, stores, isWidget }) => {
  const phoneNumber = member.get('phoneDeliveryInfo');
  const email = member.get('email');
  const { formatMessage } = stores.Intl;

  if (
    isWidget ||
    !phoneNumber ||
    (phoneNumber.isEmpty() && email && !email.includes(Api.Utils.SMS_SUPU_EMAIL_DOMAIN))
  ) {
    return (
      <>
        <b>{formatMessage({ id: 'delivery.title' })}</b>: {formatMessage({ id: 'delivery.email' })}
      </>
    );
  } else if (!email || email.includes(Api.Utils.SMS_SUPU_EMAIL_DOMAIN)) {
    return (
      <>
        <b>{formatMessage({ id: 'delivery.title' })}</b>: {formatMessage({ id: 'delivery.phone' })}
      </>
    );
  } else {
    return (
      <>
        <b>{formatMessage({ id: 'delivery.title' })}</b>: {formatMessage({ id: 'delivery.email' })},{' '}
        {formatMessage({ id: 'delivery.phone' })}
      </>
    );
  }
};

@observer
class MemberHeader extends Component {
  constructor(props) {
    super(props);
    this.icon = <RandomIcon />;
  }

  render() {
    let updated = this.props.updated;
    let props = this.props;
    const {
      nameToShow,
      isGroup,
      isDone,
      isPrivate,
      roleOrEventText,
      showBouncedIcon,
      hasAutoSavedData
    } = props;
    const { formatMessage } = this.props.stores.Intl;
    const bouncedIconTooltipAndAriaLabelText = formatMessage({
      id: 'notification_bounced_back_message'
    });
    const lockIconTooltip = formatMessage({ id: 'private_recipient_tooltip' });
    let isMSWFEnabledWidget =
      this.props.stores.UserSettings.isMultiSignerFeatureEnabled() && isWidget(this.props.type);
    let isMWFInFlightEnabled =
      props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled() &&
      isWidget(this.props.type);
    const sequential = this.props.workflow === 'SEQUENTIAL';

    const memberProps = {
      className: [classNames.MEMBER, updated ? TRANSITION_ANIMATION : ''],
      tabIndex: 0,
      workflow: isGroup ? 'PARALLEL' : props.workflow
    };

    return (
      <MemberListItem {...memberProps}>
        {this.icon}
        {isMSWFEnabledWidget || isMWFInFlightEnabled ? (
          <MemberListInformationMultiSignerWebForm {...props} roleOrEventText={roleOrEventText} />
        ) : (
          <MemberListItemInformation>
            <OverflowEllipsisHeaderText className={classNames.MEMBER_NAME}>
              {sequential && !props.isGroupMember ? props.memberIndex + '.' : ''}{' '}
              {Api.Utils.trimSmsDomain(nameToShow)}
              {isPrivate && (
                <div
                  role="alert"
                  title={lockIconTooltip}
                  aria-label={lockIconTooltip}
                  style={{ display: 'inline-block', position: 'relative' }}
                >
                  <LockIcon />
                </div>
              )}
            </OverflowEllipsisHeaderText>
            {!isGroup && (
              <RoleText data-automation-id="participant-event-text" title={roleOrEventText}>
                {roleOrEventText}
              </RoleText>
            )}
          </MemberListItemInformation>
        )}
        {showBouncedIcon && (
          <div
            role="alert"
            data-automation-id="bounced-icon-cb"
            title={bouncedIconTooltipAndAriaLabelText}
            aria-label={bouncedIconTooltipAndAriaLabelText}
            style={{ height: '100%', width: '100%' }}
          >
            <BouncedIcon style={{ float: 'right', 'margin-left': 'auto' }} />
          </div>
        )}
        {isDone && !props.isGroupMember && (
          <DoneCheckmark
            className={classNames.MEMBER_POPOVER}
            size={'S'}
            style={{ marginTop: hasAutoSavedData ? '2px' : 'unset' }}
          />
        )}
        {hasAutoSavedData && (
          <div
            aria-label={formatMessage({ id: 'live_form_data.icon.aria_label' })}
            title={formatMessage({ id: 'live_form_data.icon.aria_label' })}
            style={{ float: 'right', marginLeft: isDone && !props.isGroupMember ? '3px' : 'Auto' }}
          >
            <AutoSavedDataIcon />
          </div>
        )}
      </MemberListItem>
    );
  }
}

/**
 * An individual member view
 *
 * @prop member {Model} the member to render
 * @prop participantSet {Model} the participant set member belongs to
 * @prop workflow {string}
 */
@inject('agreement', 'stores')
@observer
class Member extends Component {
  constructor(props) {
    super(props);
    this.resizeOverlay = false; // this sticky popover inner body might change (see getOverlayBody) and we may want to recalculate force shouldUpdateComponent to be true to force recalculation of the height of the overlay

    this.isSender = this.props.agreement.members.isSender();
    Api = this.props.stores.Api;
    MEMBER_STATUS = Api.Agreements.Members.STATUS;
    MEMBER_DONE_STATUSES = [MEMBER_STATUS.COMPLETED, MEMBER_STATUS.WAITING_FOR_OTHERS];
    this._resetMembers();

    this.memberOverlayRef = React.createRef();
    this.memberHeaderRef = React.createRef();

    this.isEditable = this.canEditMember;

    // This is ONLY used when SENDER_CHOICE is not valid.
    this.trueReplace = !this.props.stores.UserSettings.canOriginalSignerStillParticipate();

    this.popoverTitle = '';
    this.memberIndex = this.props.memberIndex || 0;

    this.hasAutoSavedData = false;
    this.groupSettingsObservable = this.props.stores.getObservableModel(
      this.props.stores.agreementGroupSettings.settings
    );

    this.isWaitingForNotarization = props.agreement.get('status') === 'WAITING_FOR_NOTARIZATION';
    this.recipientNameAvailable = this.props.stores.UserSettings.isRecipientNameAvailable();
    this.recipientNameEnabled = this.props.stores.UserSettings.isRecipientNameEnabled();
    // NOTE: observable is registered in shouldComponentUpdate()
    // since the model may change after an edit.

    // use state for view so we can reset the view after sticky
    // is hidden w/o re-rendering.
    this.state = {
      view: VIEWS.DEFAULT
    };
  }

  @action
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let viewChange = this.state.view !== nextState.view;

    // update unless going to full view (i.e., sticky popover gets hidden)
    if (nextState.view !== VIEWS.DEFAULT) {
      // register model if it was updated or never registered
      if (viewChange && (this.updated || !this.observable)) {
        // if it was updated, re-register new model
        if (this.observable) this.observable._dispose();

        // add observable to watch for replace and edit auth
        this.observable = this.props.stores.getObservableModel(this.member);
      }
      return true;
    }

    // When the group settings is fetched, re-render the component
    if (this.props.stores.agreementGroupSettings.settings.hasChanged()) return true;

    let update = nextState.view !== this.state.view;
    // silent update (no render)
    this.state.view = VIEWS.DEFAULT; // eslint-disable-line
    return update;
  }

  componentDidUpdate() {
    this.resizeOverlay = false;
    this._resetMembers();
  }

  componentDidMount() {
    // Make members accessible by using the `Enter` key
    if (this.memberOverlayRef.current) {
      this.memberOverlayEl = ReactDOM.findDOMNode(this.memberOverlayRef.current);
      this.memberOverlayEl.onkeydown = this.onKeyDown.bind(this);
    }
  }

  onKeyDown(e) {
    if (KEYS.isEnter(e)) {
      this.toggleOverlay(true);
    }
    return true;
  }

  _resetMembers() {
    this.isMyTurn = this.props.agreement.members.isMyTurn(this.props.participantSet);
    this.memberIsDone = MEMBER_DONE_STATUSES.includes(this.props.participantSet.get('status'));
  }

  @computed
  get canEditMember() {
    // observable to react to
    this.props.agreement.observable.status; // eslint-disable-line

    this.isEditable =
      this.props.stores.Env.loggedIn &&
      this.isSender &&
      !this.props.stores.accountSharing.cannotModify() &&
      !this.memberIsDone &&
      (this.canEditAuthentication() ||
        (isWidget(this.props.type)
          ? this.canReplaceWidgetCounterSigner()
          : this.canReplaceSigner()));
    return this.isEditable;
  }

  canEditAuthentication() {
    return (
      this.props.stores.UserSettings.canEditAuthentication() &&
      !this.isWaitingForNotarization &&
      (this.canEditWidgetAuthentication() ||
        (!isWidget(this.props.type) &&
          this.props.agreement.isCanceled && // DCES-4327822: "cancel" is not valid for web forms
          !this.props.agreement.isCanceled()))
    );
  }

  canEditWidgetAuthentication() {
    return (
      this.props.stores.Floodgate.hasWebformEditAuthenticationEnabled() &&
      isWidget(this.props.type) &&
      this.props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled()
    );
  }

  /*
   * Show live form field data when:
   * 1. The feature is enabled
   * 2. The agreement is out for completion or complete
   * 3. The type of CB is not WEBFORM or WIDGET, since they are not supported
   * */
  showLiveFormData() {
    return (
      !isWidget(this.props.type) &&
      !isWebForm(this.props.type) &&
      this.props.stores.agreementGroupSettings.isLiveFormFieldDataFeatureEnabled() &&
      (this.props.agreement.isOutForCompletion() || this.props.agreement.isComplete())
    );
  }

  /**
   * Based on settings and if originator, is the replaceSigner feature available?
   * A user can replace a signer only if these settings apply:
   * 1. allow_replace_signer is true
   * 2. replace_signer_options is ORIGINAL_RECIPIENT_CANNOT_PARTICIPATE or SENDER_OPTION
   * >> There are two other replace signer options:
   * ORIGINAL_RECIPIENT_CAN_PARTICIPATE (old delegate flow where the original signer can still sign the agreement,
   * ie. creates a group for that participant set consisting of both the original participant and the new one
   * SENDER_CHOICE (SENDER_CHOICE (Users in my account can select whether the original recipient can or cannot
   * participate after being replaced). At this time, the option the user has chosen is not honored. It will always
   * default to ORIGINAL_SIGNER_CANNOT_PARTICIPATE as the change is a PUT to the participant set, but there may be
   * changes in the future to handle this the right way
   * 3. The member does not have delegation role (delegate_to_x)
   * 4. Type of agreement is not a megasign_child (controlled by floodgate flag)
   * 5. The user has to be logged in (needs to be logged in to to make the user settings call)
   * 6. We can modify a future recipient when agreement is in flight- DCES-4334655
   * 7. It's not a Only I Sign or Self Sign workflow
   * 8. Agreement is not in WAITING_FOR_NOTARIZATION status
   * 9. The member does not have electronic sealer role
   * 10. It's a Send Only To Self workflow
   * 11. It is not an undefined witness participant
   */
  canReplaceSigner() {
    const isElectronicSealer =
      this.props.participantSet.get('role') === Api.Helpers.Members.ROLE.ELECTRONIC_SEALER;
    //do not allow replace if witness is undefined
    const isWitness = this.props.participantSet.get('role') === Api.Helpers.Members.ROLE.WITNESS;
    const isUndefinedWitness = isWitness && this.member && !this.member.get('email');
    //Api.Helpers.Members.ROLE.WITNESS
    return (
      (this.props.agreement.members.isSendOnlyToSelfFlow() ||
        (this.props.agreement.isOutForCompletion() &&
          !this.props.agreement.members.isOnlyISignWorkflow())) &&
      this.props.stores.UserSettings.canReplace() &&
      !this.isWaitingForNotarization &&
      !this.props.participantSet.get('role').includes('DELEGATE') &&
      !isElectronicSealer &&
      !isUndefinedWitness
    );
  }

  canReplaceWidgetCounterSigner() {
    return (
      !this.props.isDCWeb &&
      this.props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled() &&
      this.props.agreement.isActive()
    );
  }

  onHidePopover() {
    if (this.observable) this.observable._dispose();
    this.observable = null;

    // reset to default state so it shows the default view
    // on next hover
    if (this.state.view !== VIEWS.DEFAULT) {
      this.setState({ view: VIEWS.DEFAULT });
    }
  }

  showView(view, options = {}) {
    if (options.analytics !== false) {
      analytics.clicked(CLICK_EVENT[view]);
    }
    if (this.memberOverlayRef.current && !this.props.isCC) {
      this.memberOverlayRef.current.makeSticky(true);
    }
    this.setState({ view });
  }

  setPopoverTitle(title) {
    this.popoverTitle = title;
  }

  toggleOverlay(show) {
    this.memberOverlayRef.current.el[show ? 'show' : 'hide']();
  }

  getPrivateMessageView() {
    const { formatMessage } = this.props.stores.Intl;
    let privateMessage = this.member.get('privateMessage');

    return this.isSender && privateMessage ? (
      <MemberInfo>
        <div>
          <b>{formatMessage({ id: 'participants.private_message' })}</b>:{' '}
          <AgreementMessage text={privateMessage} />
          {this.getPrivateMessageLinkView()}
        </div>
      </MemberInfo>
    ) : null;
  }

  getPrivateMessageLinkView() {
    if (
      this.props.isCC ||
      !(
        this.props.stores.Floodgate.hasWebformCounterSignerPrivateMsgEditEnabled() &&
        isWidget(this.props.type) &&
        !this.props.isGroupMember
      )
    ) {
      return null;
    }
    const { formatMessage } = this.props.stores.Intl;
    return (
      <LinkStyledButton
        quiet
        variant="action"
        className={'edit_private_message'}
        onClick={() => this.showView(VIEWS.EDIT_PRIVATE_MESSAGE)}
      >
        {formatMessage({ id: 'actions.edit' })}
      </LinkStyledButton>
    );
  }

  getStyledLink(view, label, autoFocus) {
    const className = autoFocus ? 'replace_link replace-link-focus' : 'replace_link';
    return (
      <LinkStyledButton
        id={view}
        quiet
        autoFocus={autoFocus}
        variant="action"
        className={className}
        onClick={() => this.showView(view)}
      >
        {label}
      </LinkStyledButton>
    );
  }

  /**
   * Replace signer link view that will trigger the replace signer view
   * If setting is : replace_signer_option, default to original_signer_cannot_participate (true replace)
   * If setting is: original_signer_can_participate or signers_choice (delegate case, will create a group 99% of
   * enterprise set to this), show string: "add alternate signer"
   */
  getReplaceSignerLinkView() {
    if (!this.isEditable || !this.canReplaceSigner()) return null;

    const { formatMessage } = this.props.stores.Intl,
      replaceSignerLabel = formatMessage({ id: 'replace.replace_participant' }),
      alternateSignerLabel = formatMessage({ id: 'replace.add_alternate_participant' });

    if (!this.isMyTurn && !this.props.stores.UserSettings.canReplaceFuture()) {
      return null;
    }
    // we do not want to show Alternate Recipient Button if this signer has witness role or has to define witness, as witness isn't supported with recipient group for now
    const isWitness = this.props.participantSet.get('role') === Api.Helpers.Members.ROLE.WITNESS;
    const isDefinedWitness = isWitness && this.member && this.member.get('email');
    if (isDefinedWitness && !this.props.stores.UserSettingsSearch.isWitnessReplaceAvailable()) {
      return null;
    }
    if (this.props.isSignerWithWitness || isWitness) {
      return this.getReplaceSignerViewForSignerWithWitness(replaceSignerLabel);
    }

    if (this.props.stores.UserSettings.canSenderChooseToReplace()) {
      return (
        <Fragment>
          {this.getStyledLink(VIEWS.REPLACE, replaceSignerLabel, !this.hasAutoSavedData)}
          {this.getStyledLink(VIEWS.ADD_ALTERNATE, alternateSignerLabel, false)}
        </Fragment>
      );
    } else {
      if (this.trueReplace) {
        return (
          <Fragment>
            {this.getStyledLink(VIEWS.REPLACE, replaceSignerLabel, !this.hasAutoSavedData)}
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {this.getStyledLink(VIEWS.ADD_ALTERNATE, alternateSignerLabel, !this.hasAutoSavedData)}
          </Fragment>
        );
      }
    }
  }

  getReplaceSignerViewForSignerWithWitness(replaceSignerLabel) {
    if (this.props.stores.UserSettings.canSenderChooseToReplace() || this.trueReplace) {
      return <Fragment>{this.getStyledLink(VIEWS.REPLACE, replaceSignerLabel, true)}</Fragment>;
    }
    return <Fragment />;
  }

  /**
   * Replace cc link view that will trigger the replace signer view
   */
  getReplaceCCLinkView() {
    if (
      !this.isEditable ||
      !this.canReplaceWidgetCounterSigner() ||
      this.member.get('email') === ''
    ) {
      return null;
    }
    const { formatMessage } = this.props.stores.Intl,
      replaceCCLabel = formatMessage({ id: 'replace.replace_cc_participant' });

    return <Fragment>{this.getStyledLink(VIEWS.REPLACE, replaceCCLabel, true)}</Fragment>;
  }

  /**
   * Replace counter signer link view that will trigger the replace signer view
   */
  getReplaceCounterSignerLinkView() {
    if (
      !this.isEditable ||
      !this.canReplaceWidgetCounterSigner() ||
      this.member.get('email') === ''
    ) {
      return null;
    }
    const { formatMessage } = this.props.stores.Intl,
      replaceCounterSignerLabel = formatMessage({ id: 'replace.replace_counter_participant' });

    return (
      <Fragment>{this.getStyledLink(VIEWS.REPLACE, replaceCounterSignerLabel, true)}</Fragment>
    );
  }

  @action
  getReplaceSignerView(view) {
    const { formatMessage } = this.props.stores.Intl;

    switch (view) {
      case VIEWS.REPLACE:
        isWidget(this.props.type)
          ? this.props.isCC
            ? this.setPopoverTitle(formatMessage({ id: 'replace.replace_cc_participant' }))
            : this.setPopoverTitle(formatMessage({ id: 'replace.replace_counter_participant' }))
          : this.setPopoverTitle(formatMessage({ id: 'replace.replace_participant' }));
        break;

      case VIEWS.ADD_ALTERNATE:
        this.setPopoverTitle(formatMessage({ id: 'replace.add_alternate_participant' }));
        break;

      default:
        break;
    }

    return (
      <ReplaceSignerView
        participantSet={this.props.participantSet}
        member={this.member}
        isPrivate={this.member.get('isPrivate')}
        toggleOverlay={show => this.toggleOverlay(show)}
        view={view}
        isWidget={isWidget(this.props.type)}
        isSignerWithWitness={this.props.isSignerWithWitness}
        isCC={this.props.isCC}
        isGroupMember={this.props.isGroupMember}
      />
    );
  }

  @action
  getEditAuthenticationView() {
    const { formatMessage } = this.props.stores.Intl;
    this.setPopoverTitle(formatMessage({ id: 'edit_authentication.title' }));

    return (
      <EditAuthentication
        type={this.props.type}
        isCC={this.props.isCC}
        member={this.member}
        participantSet={this.props.participantSet}
        toggleEditAuthentication={() => this.showView(VIEWS.EDIT_AUTH)}
        toggleOverlay={show => this.toggleOverlay(show)}
      />
    );
  }

  getEditSignerAuthenticationLinkView() {
    if (!this.isEditable || !this.canEditAuthentication()) return;

    const { formatMessage } = this.props.stores.Intl;
    return (
      <LinkStyledButton
        quiet
        variant="action"
        className={'edit_authentication'}
        onClick={() => this.showView(VIEWS.EDIT_AUTH)}
      >
        {formatMessage({ id: 'actions.edit' })}
      </LinkStyledButton>
    );
  }

  @action
  getEditPrivateMessageView() {
    const { formatMessage } = this.props.stores.Intl;
    this.setPopoverTitle(formatMessage({ id: 'participants.edit_private_message.title' }));

    return (
      <EditPrivateMessageView
        participantSet={this.props.participantSet}
        member={this.member}
        toggleOverlay={show => this.toggleOverlay(show)}
        isWidget={this.props.isWidget}
        isCC={this.props.isCC}
      />
    );
  }
  getOverlayBody() {
    this.resizeOverlay = true;
    switch (this.state.view) {
      case VIEWS.REPLACE:
        return this.getReplaceSignerView(VIEWS.REPLACE);
      case VIEWS.ADD_ALTERNATE:
        return this.getReplaceSignerView(VIEWS.ADD_ALTERNATE);
      case VIEWS.EDIT_AUTH:
        return this.getEditAuthenticationView();
      case VIEWS.EDIT_PRIVATE_MESSAGE:
        return this.getEditPrivateMessageView();
      case VIEWS.EDIT_RECIPIENT_NAME:
        const { formatMessage } = this.props.stores.Intl;
        this.setPopoverTitle(formatMessage({ id: 'replace.edit_name_title' }));
        return (
          <EditRecipientName
            {...this.props}
            setPopoverTitle={str => this.setPopoverTitle(str)}
            member={this.member}
            toggleOverlay={show => this.toggleOverlay(show)}
          />
        );
      default:
        this.resizeOverlay = false;
        return null;
    }
  }

  getAuthenticationMethodLabel(authenticationMethod) {
    const { formatMessage } = this.props.stores.Intl;
    // TODO: Removal of authentication.noneLabel (https://jira.corp.adobe.com/browse/DCES-4472346)
    return authenticationMethod.toLowerCase() === 'none'
      ? formatMessage({ id: 'authentication.noneLabel' })
      : formatMessage({ id: 'authentication.' + authenticationMethod.toLowerCase() });
  }

  /**
   * Returns the description of the most recent event associated with the recipient
   * while taking the recipient's role and date of event into consideration.
   *
   * Examples:
   * "Signature requested on Mar 11, 2024"
   * "Signed on Mar 20, 2024"
   * "E-signature verified on Mar 18, 2024"
   * "Email bounced on Mar 18, 2024"
   *
   * @returns description of most recent event associated with the recipient
   */
  getRoleOrEventTextForRecipient = () => {
    let textId, roleOrEventText;
    const { formatMessage, formatDate } = this.props.stores.Intl;
    const role = this.props.participantSet.get('role');

    // get the most recent event associated with that member
    const memberEvents = this.props.memberEvents ? Array.from(this.props.memberEvents) : []; // account for undefined or empty memberEvents
    const memberEvent = memberEvents.length > 0 ? memberEvents[memberEvents.length - 1] : null;

    if (memberEvent) {
      const type = memberEvent.get('type');
      textId = `event.${type.toLowerCase()}`;
      if (type !== 'EMAIL_BOUNCED') {
        // description of the event (except *BOUNCED events) is dependent on the member's role
        textId += `.${role.toLowerCase()}`;
      }

      roleOrEventText = formatMessage(
        { id: textId },
        {
          date: formatDate(memberEvent.get('date'), {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          }),
          participantEmail: memberEvent.get('participantEmail')
        }
      );
    } else if (this.props.isCC) {
      textId = 'participants.ccParticipant';
      roleOrEventText = formatMessage({ id: textId });
    } else if (!this.props.isGroupMember) {
      textId = 'participants.' + role.toLowerCase();
      roleOrEventText = formatMessage({ id: textId });
    }

    return roleOrEventText;
  };

  /**
   * Show icon if all conditions are met:
   * - is sender view and agreement is in flight
   * - unresolved bounce feature is available
   * - recipient does not belong to a group
   * - recipient is unfinished with a bounce event
   * @returns true if all the conditions for displaying the icon are met
   */
  canShowBouncedIcon = () => {
    return (
      isSenderViewOfInFlightAgreement() &&
      this.props.stores.isUnresolvedBounceIndicatorFeatureAvailable() &&
      !this.props.isGroupMember &&
      unfinishedRecipientHasBounceEvent(this.props.memberEvents)
    );
  };

  /**
   * @returns {Object} single member JSX, takes care of special cases such as hybrid and groups as well
   */
  render() {
    const { formatMessage } = this.props.stores.Intl;
    let pSet = this.props.participantSet;

    // active members may change after replace!

    const activeMembers = this.props.isCC ? pSet : pSet.getActiveMembers();
    const becameGroup = !this.props.isGroupMember && activeMembers.length > 1;
    const member = this.props.isCC ? activeMembers : activeMembers[this.memberIndex];
    const isPrivate = member.get('isPrivate');

    // save() on replace doesn't modify the id's
    // So look at Backbone's cid to see if a model was updated.
    let updated =
      this.member &&
      this.updated &&
      // it became a group
      (becameGroup ||
        // it's a true replace and model's cid does not match
        (this.trueReplace && member.cid !== this.member.cid) ||
        // add-alternate replace and false --
        //   1. if it was a single member, it becomes a group
        //   2. if it was a group, the whole groups gets re-rendered
        (!this.trueReplace && false));

    this.updated = this.member && !isEmpty(this.member.changed);

    // remember member
    this.member = member;

    // if it was NOT a group originally, but the active recipients changed
    // (after an add participant!), render as a group.
    if (becameGroup) {
      return (
        <Group
          key={pSet.cid}
          workflow={this.props.workflow}
          participantSet={pSet}
          updated={updated}
        />
      );
    }

    // make reactive
    if (this.observable) {
      // edit auth:
      this.observable.securityOption; // eslint-disable-line
      // replace:
      this.observable.email; // eslint-disable-line
    }

    // make reactive
    this.groupSettingsObservable.settings; // eslint-disable-line

    const ccOverLayForInactiveAgreement =
      this.props.stores.Floodgate.hasWebformCcPrivateMsgEnabled() ? ['click', 'hover'] : null;

    // only use the sticky overlay if necessary
    // keep props passed in clean, only pass in the overlayX specific props if necessary
    let overlayProps = {
      container: window.document.body,
      placement: 'left',
      trigger:
        this.props.isCC && !this.props.agreement.isActive()
          ? ccOverLayForInactiveAgreement
          : ['click', 'hover'],
      ...(this.isEditable
        ? {
            onHide: () => this.onHidePopover()
          }
        : {}) // only include overlayX props if editing is allowed
    };
    let role,
      status,
      roleId,
      roleText,
      secOpts,
      authenticationMethod,
      authenticationString,
      memberName,
      memberEmail,
      nameToShow,
      privateMessageView,
      authenticationView,
      formDataLastAutoSavedTime;
    if (this.props.isCC) {
      memberEmail = member.get('email');
      memberName = member.get('name');
      nameToShow = memberName || memberEmail;
    } else {
      role = pSet.get('role');
      status = pSet.get('status');
      roleId = 'participants.' + role.toLowerCase();
      roleText =
        status === 'WAITING_FOR_AUTHORING' // NOTE: not in MEMBER_STATUS!
          ? formatMessage({ id: 'participants.waiting_for_authoring' })
          : formatMessage({ id: roleId });
      secOpts = member.get('securityOption');
      authenticationMethod = secOpts && secOpts.authenticationMethod;
      authenticationString = authenticationMethod
        ? // in some cases, the member may not have a default authentication method
          this.getAuthenticationMethodLabel(authenticationMethod)
        : null;
      // in case of DIG, authenticationString will be the Provider Name
      if (authenticationMethod === DIG_AUTH) {
        authenticationString = secOpts.digAuthInfo.providerName;
      }
      memberName = member.get('name');
      memberEmail = member.get('email');
      nameToShow = memberName || memberEmail;
      formDataLastAutoSavedTime = member.get('formDataLastAutoSavedTime');
    }
    nameToShow = Api.Utils.trimSmsDomain(nameToShow);

    this.hasAutoSavedData = !!formDataLastAutoSavedTime && this.showLiveFormData();

    let replaceLink;
    if (isWidget(this.props.type)) {
      replaceLink = this.props.isCC
        ? this.getReplaceCCLinkView()
        : this.getReplaceCounterSignerLinkView();
    } else {
      replaceLink = this.getReplaceSignerLinkView();
    }

    // Witness Participation will not have name until signer provides - change name to Witness participant if not known
    role = pSet.get('role');
    const isWitness = role && role.includes('WITNESS');
    const isUndefinedWitness = isWitness && this.member && !this.member.get('email');
    if (isWitness) {
      nameToShow = nameToShow || formatMessage({ id: 'agreement.participant_witnessName' });
    } else {
      // Multi Signer Web Form - change name to unknown participant if not known
      nameToShow = nameToShow || formatMessage({ id: 'widget.participant_unknownName' });
    }

    const recipientNameLink =
      (!isWidget(this.props.type) &&
        this.recipientNameAvailable &&
        this.recipientNameEnabled &&
        !isUndefinedWitness) ||
      (isWitness &&
        !isUndefinedWitness &&
        this.props.stores.UserSettingsSearch.isWitnessReplaceAvailable()) ? (
        <EditRecipientNameLinkView
          {...this.props}
          isEditable={this.isEditable}
          onClick={() => this.showView(VIEWS.EDIT_RECIPIENT_NAME)}
        />
      ) : null;
    const recipientNameView = (
      <MemberInfo>
        {nameToShow}
        {recipientNameLink}
        {this.hasAutoSavedData && (
          <LiveFormDataView
            {...this.props}
            formDataLastAutoSavedTime={formDataLastAutoSavedTime}
            member={member}
            isDone={this.memberIsDone}
          />
        )}
      </MemberInfo>
    );

    const privateRecipientView = isPrivate ? (
      <MemberInfo>
        <b>{formatMessage({ id: 'members.private.agreement.title' })}</b>:{' '}
        {formatMessage({ id: 'members.private.agreement.value' })}
      </MemberInfo>
    ) : null;

    this.setPopoverTitle(nameToShow);

    const roleView = this.props.isCC ? null : (
      <MemberInfo>
        <b>{formatMessage({ id: 'participants.role_title' })}</b>: {roleText}
      </MemberInfo>
    );
    let authenticationLink = this.getEditSignerAuthenticationLinkView();
    authenticationView = authenticationString ? (
      <MemberInfo>
        <b>{formatMessage({ id: 'participants.authentication_title' })}</b>: {authenticationString}{' '}
        {authenticationLink}
      </MemberInfo>
    ) : null;

    privateMessageView = this.getPrivateMessageView();
    const overlayBody = this.getOverlayBody();
    const roleOrEventText = this.getRoleOrEventTextForRecipient();

    return (
      <OverlayTriggerX
        resizeOverlay={this.resizeOverlay}
        ref={this.memberOverlayRef}
        {...overlayProps}
      >
        <span>
          {/* will not render without the stupid span!!!! argh!! - likely a bug in overlay trigger */}
          <MemberHeader
            {...this.props}
            nameToShow={nameToShow}
            updated={updated}
            isDone={this.memberIsDone}
            isPrivate={isPrivate}
            memberIndex={pSet.get('order')}
            ref={this.memberHeaderRef}
            roleOrEventText={roleOrEventText}
            showBouncedIcon={this.canShowBouncedIcon()}
            hasAutoSavedData={this.hasAutoSavedData}
          />
        </span>
        {overlayBody ? (
          <MembersPopover
            autoFocus
            role={'alertdialog'}
            height={'100vh'}
            title={this.popoverTitle}
            hasAutoSavedData={this.hasAutoSavedData}
          >
            {overlayBody}
          </MembersPopover>
        ) : (
          <MembersPopover
            height={'50vw'}
            className={classNames.MEMBER_POPOVER}
            title={recipientNameView}
            subtitle={getSubTitle(this.member, isWidget(this.props.type), this.isSender)}
            hasAutoSavedData={this.hasAutoSavedData}
          >
            {(this.props.stores.UserSettings.isMultiSignerFeatureEnabled() ||
              this.props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled()) &&
            nameToShow === formatMessage({ id: 'widget.participant_unknownName' })
              ? formatMessage({ id: 'widget.participant_helpText' })
              : ''}
            {replaceLink}
            {roleView}
            {authenticationView}
            {privateMessageView}
            {privateRecipientView}
            <DeliveryMessageView
              member={this.member}
              stores={this.props.stores}
              isWidget={isWidget(this.props.type)}
            />
          </MembersPopover>
        )}
      </OverlayTriggerX>
    );
  }
}
export default WithToastMessage(Member);

// for unit tests
export { MemberHeader, MembersPopover };
