import React, { Component, Fragment } from 'react';
import { providers } from 'dc-core';
import Wait from '@react/react-spectrum/Wait';
import PropTypes from 'prop-types';
import { WithToastMessage } from 'as-ducati-core';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import styled from 'styled-components';
import PopoverX from 'common/popover-x';
import { DialogFooter } from 'common/styledElements';
import ActionButton, { ButtonX } from 'common/actionButton';
import { ActionSection } from 'common/styledElements';
import {
  redirectAction,
  getLocationHref,
  getDestination,
  fetchAssetUriforModernRequestSignatures,
  isModernRSDefaultExperienceDisabled,
  isPDFCompatibleWithModernRS
} from 'utils/helper';
import stores from 'stores';
import * as classNames from 'context-boards/classNames';
import analytics from 'utils/analytics';
import { PARAM_MS_TEAM_EMBEDDED } from 'stores/constants';

const StyledActionButton = styled(ActionButton)`
  svg {
    margin-left: 0;
  }
`;

const StyledWait = styled(Wait)`
  margin-right: 21px;
`;

const DC_WORKFLOW_URL_TEMPLATE = (path, searchParams) =>
  `${window.location.origin}${path}${searchParams}`;

const SwitchWarning = ({ actionButton, onSwitch, analyticsEventType }) => {
  const { formatMessage } = stores.Intl,
    title = formatMessage({ id: 'account_sharing.warning.title' }),
    tip = formatMessage({ id: 'account_sharing.warning.tip' }),
    cancelStr = formatMessage({ id: 'cancel.title' }),
    switchStr = formatMessage({ id: 'account_sharing.warning.switch' }),
    name = stores.accountSharing.getName() || stores.Env.sharerEmail || stores.Env.sharerUserId,
    warningStr = formatMessage(
      { id: 'account_sharing.warning.message' },
      {
        name: <b>{name}</b>
      }
    );
  let overlay;

  // NOTE: DIV in first child of overlay trigger is needed for it to work!
  return (
    <OverlayTrigger trigger="click" container={window.document.body} ref={el => (overlay = el)}>
      <div>{actionButton}</div>
      <PopoverX title={title} width="370px">
        {warningStr}
        <p>{tip}</p>
        <DialogFooter>
          <ButtonX
            label={cancelStr}
            variant="secondary"
            quiet
            onClick={() => overlay.hide()}
            analyticsEventType={analyticsEventType}
            analyticsSubType={'switchCancel'}
          />
          <ButtonX
            label={switchStr}
            variant="cta"
            quiet={false}
            onClick={e => onSwitch(e)}
            analyticsEventType={analyticsEventType}
            analyticsSubType={'switchAcct'}
          />
        </DialogFooter>
      </PopoverX>
    </OverlayTrigger>
  );
};

/**
 * Common 'go to VIEW' component -- any view from POST views calls
 */
class PostView extends Component {
  constructor(props) {
    super(props);
    this.views = stores.agreement.views;
    this.state = { waiting: false, siginingUrl: null };
  }

  showLoader(val) {
    this.setState({ waiting: val });
  }

  componentDidMount() {
    if (this.props.useSigningUrl) {
      this.fetchSigningUrls();
    }
  }

  /*
   Upon clicking Sign action from MS Teams Embedded Adobe Sign, to open esign link in a new tab we need to redirect the user to signing URLs instead of view URLs,
   as POST /agreements/ID/views API with autoLoginUser=true returns PERMISSION_DENIED, likely because the webapp token doesn't have login scope. (DCES-4469874)
  */
  fetchSigningUrls() {
    const myEmail = this.props.agreement.members.getMyself({ isMyTurn: true }).get('email');

    return this.props.agreement.signingUrls
      .fetch()
      .then(() => {
        this.setState({ signingUrl: this.props.agreement.signingUrls.getEsignUrlByEmail(myEmail) });
      })
      .catch(error => {
        analytics.failed(error);
        this.props.showToast(error);
      });
  }

  async isPDFCompatible(assetUri) {
    let isCompat = true;
    if (
      this.props.stores.Floodgate.hasModernRSLegacySignFlagEnabled() ||
      this.props.stores.Floodgate.hasModernRSTeamFlagEnabled()
    ) {
      // Check to see if the PDF is compatible with Modern RS
      // Bypass if hasModernRSCompatibilityCheckDisabledFlag is present
      if (!this.props.stores.Floodgate.hasModernRSCompatibilityCheckDisabledFlag()) {
        isCompat = await isPDFCompatibleWithModernRS(assetUri);
      }
    }
    return isCompat;
  }

  async switchAccount(e) {
    const { formatMessage } = stores.Intl;
    let options = {
        // Relative path is already set for isViewAgr
        // We only need the relative path in case of DC Web
        calculateRelativePath: this.isViewAgr() ? false : this.props.isDCWeb ? true : false,
        openInNewTab: this.props.openInNewTab,
        useSignURL: this.props.useSignURL,
        userEvent: e
      },
      url = this.props.useSigningUrl ? this.state.signingUrl : this.viewUrl;

    // send custom analytics event for click on edit and modify to log destination
    if (!!e && e.type === 'click' && !!e.currentTarget && !!e.currentTarget.className) {
      let destination = getDestination(getLocationHref(url, options));
      if (e.currentTarget.className.indexOf(classNames.AGREEMENT_EDIT) !== -1) {
        analytics.clicked(
          'edit:clicked:' + this.props.agreement.attributes.status + ':' + destination
        );
      }
      if (e.currentTarget.className.indexOf(classNames.AGREEMENT_MODIFY) !== -1) {
        analytics.clicked(
          'modify:clicked:' + this.props.agreement.attributes.status + ':' + destination
        );
      }
    }
    if (this.props.isDCWeb) {
      const intentProviders = await providers.intent();
      const modernRSPreferLegacy = await isModernRSDefaultExperienceDisabled();
      const isModernRequestSignaturesEnabled =
        !modernRSPreferLegacy && (await intentProviders.isModernRequestSignaturesEnabled());
      try {
        /*
        Return true if
          1. Modern RS Feature Flag is enabled for user AND
          2. url contains /public/compose AND
          3. Agreement type is not ESIGN. This is to make sure Sign Drafts are not redirected to modern RS experience.
      */
        if (
          isModernRequestSignaturesEnabled &&
          url.indexOf('/public/compose') !== -1 &&
          this.props.agreementType !== AGREEMENT_TYPES.ESIGN
        ) {
          this.showLoader(true);
          options.assetUri = await fetchAssetUriforModernRequestSignatures();
          if (!(await this.isPDFCompatible(options.assetUri))) {
            delete options.assetUri;
            options.disableModernRS = true;
          }
          this.showLoader(false);
        }
      } catch (error) {
        analytics.failed(error);
        this.showLoader(false);
        this.props.showToast({
          text: formatMessage({ id: 'rsprovider.createdraft.api.error' }),
          type: 'error'
        });
        return;
      }
    }
    redirectAction(url, options);
  }

  showAmbiguousSignerToast() {
    const { formatMessage } = stores.Intl;
    this.props.showToast(
      {
        text: formatMessage({ id: 'msteams_ambiguous_signer_message' }),
        type: 'info'
      },
      { fixed: true }
    );
  }

  haveView() {
    if (this.isSeamlessEnabledForApp() && !this.views.list.findWhere({ name: 'FILL_SIGN' })) {
      this.populateViewsForSeamlessEnabled();
    } else if (
      !this.props.isDCWeb &&
      this.props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled() &&
      this.props.stores.agreementType === AGREEMENT_TYPES.WIDGET &&
      this.props.name === 'AUTHORING' &&
      // Release 14.2 allow sharee to edit webform when aasWebformModifyRollout is true
      // aasWebformEditRollout added from PR https://git.corp.adobe.com/Adobesign/core_app/pull/8818,
      // and it was renamed in PR 8909 as context-dropin plugin env property
      (!this.props.stores.accountSharing.isAcctSwitched() ||
        this.props.stores.Env.aasWebformModifyRollout)
    ) {
      this.viewsObject = this.views.list.findWhere({ name: 'MODIFY' });
    } else {
      this.viewsObject =
        this.views.list.findWhere({ name: this.props.name }) ||
        (this.props.altName && this.views.list.findWhere({ name: this.props.altName }));
    }
    return !!this.viewsObject;
  }

  isSeamlessEnabledForApp() {
    return (
      (this.props.isDCWeb || this.props.stores.Env.isSeamlessAuthoringEnabled) &&
      this.props.agreement.isAuthoring() &&
      !!this.views.list.findWhere({
        name: this.props.stores.Api.Agreements.Views.VIEW_NAME.COMPOSE
      })
    );
  }

  populateViewsForSeamlessEnabled() {
    if (this.props.stores.UserSettings.isUnifiedEditModifyOnManagePageAvailable()) {
      if (this.props.name === 'AUTHORING') {
        this.viewsObject = this.views.list.findWhere({ name: 'COMPOSE' });
      } else if (this.props.name === 'MODIFY') {
        this.viewsObject = '';
      } else {
        this.viewsObject =
          this.views.list.findWhere({ name: this.props.name }) ||
          (this.props.altName && this.views.list.findWhere({ name: this.props.altName }));
      }
    } else if (
      this.props.name === 'MODIFY' &&
      !!this.views.list.findWhere({ name: this.props.stores.Api.Agreements.Views.VIEW_NAME.MODIFY })
    ) {
      this.viewsObject = this.views.list.findWhere({ name: 'COMPOSE' });
    } else {
      this.viewsObject =
        this.views.list.findWhere({ name: this.props.name }) ||
        (this.props.altName && this.views.list.findWhere({ name: this.props.altName }));
    }
  }

  isDCWorkflowAction() {
    return (
      this.props.name === 'AUTHORING' &&
      this.props.isDCWeb &&
      (this.props.stores.agreementType === AGREEMENT_TYPES.WIDGET ||
        this.props.stores.agreementType === AGREEMENT_TYPES.MEGASIGN_PARENT)
    );
  }

  // view-agr should NOT use post views to get the url since it
  // may have switch action on it.
  isViewAgr() {
    return this.props.analyticsEventType === 'ViewAgrLink';
  }

  get viewUrl() {
    if (!this.viewsObject) return '';
    const fixedUrl = `/public/agreements/view/${stores.agreement.id}?type=${stores.agreementType}`;
    let url = this.isViewAgr() ? fixedUrl : this.viewsObject.get('url');
    url =
      this.isViewAgr() && this.props.isMSTeamEmbedded ? url + `&${PARAM_MS_TEAM_EMBEDDED}` : url;

    url = this.props.isDCWeb ? url + '&app=dc' : url;

    // Check if this action should target the new V4 workflows in Acrobat Web. For example,
    // the edit web form action should redirect the user to '/link/webform/?id=<agreement_id>'
    if (this.isDCWorkflowAction()) {
      const path =
        this.props.stores.agreementType === AGREEMENT_TYPES.WIDGET
          ? '/link/webform/'
          : '/link/sendinbulk/';
      const hostURL = new URL(window.location.href);
      const queryParams = hostURL.searchParams;
      queryParams.set('id', stores.agreement.id);
      url = DC_WORKFLOW_URL_TEMPLATE(path, hostURL.search);
    }

    // add current sharer to url
    // NOTE: BE only supports sharerUserId
    if (stores.Env.hasSharer()) {
      url += (/\?/.test(url) ? '&' : '?') + `sharerUserId=${stores.accountSharing.getUserId()}`;
    }
    return url;
  }

  render() {
    if (!this.haveView()) return null;

    const { formatMessage } = stores.Intl,
      buttonLabel = this.props.labelId
        ? formatMessage({ id: this.props.labelId })
        : formatMessage({ id: 'actions.view.agreement' }),
      isAction = this.props.variant !== 'cta',
      ButtonType = isAction ? StyledActionButton : ButtonX,
      withWarning =
        this.props.switchAcctWarning !== false && stores.accountSharing.shouldShowWarning(),
      buttonProps = {
        ...(withWarning
          ? {} // handled by overlay trigger
          : {
              onClick: this.props.showAmbiguousUserToast
                ? this.showAmbiguousSignerToast.bind(this)
                : this.switchAccount.bind(this)
            }),
        ...(isAction ? { quite: '' } : { variant: this.props.variant })
      },
      ButtonComp = () => (
        <ButtonType
          className={this.props.className}
          label={buttonLabel}
          icon={this.state.waiting ? <StyledWait /> : this.props.icon}
          requireLogin={false}
          analyticsEventType={this.props.analyticsEventType}
          openComponent={this.props.openComponent}
          openComponentDelay={this.props.startupActionDelay}
          {...buttonProps}
        />
      ),
      RenderedButton = isAction ? (
        <ActionSection>
          <ButtonComp />
        </ActionSection>
      ) : (
        <ButtonComp />
      );

    return withWarning ? (
      <SwitchWarning
        actionButton={RenderedButton}
        onSwitch={e => this.switchAccount(e)}
        analyticsEventType={this.props.analyticsEventType}
      />
    ) : (
      <Fragment>{RenderedButton}</Fragment>
    );
  }
}

PostView.displayName = 'PostView';
PostView.propTypes = {
  name: PropTypes.string, // the name of the view
  altName: PropTypes.string, // an alternate name of the view
  analyticsEventType: PropTypes.string,
  labelId: PropTypes.string,
  variant: PropTypes.string, // 'cta' etc.
  switchAcctWarning: PropTypes.bool
};

PostView.defaultProps = {
  className: '',
  icon: null,
  useSignURL: false,
  openInNewTab: false
};

export default WithToastMessage(PostView);
