export const CONTEXT_BOARD_SINGLE = 'context_board_single';
export const THUMBNAIL_SECTION = 'thumbnail_section';
export const SUMMARY_INFO_SECTION = 'summary_info_section';
export const SUMMARY_INFO_CONTAINER = 'summary_info_container';
export const MEMBER_INFO_SECTION = 'member_info_section';
export const ACTIVITY_INFO_SECTION = 'activity_info_section';
export const REMINDERS_VIEW_SECTION = 'reminders_view_section';
export const CANCEL_AGREEMENT_SECTION = 'cancel_agreement_section';
export const DOWNLOAD_AUDIT_SECTION = 'download_audit_section';
export const DOWNLOAD_ORIGINATOR_FILES_SECTION = 'download_originator_files_section';
export const DOWNLOAD_PDF_SECTION = 'download_pdf_section';
export const DOWNLOAD_ERROR_REPORT_SECTION = 'download_error_report_section';
export const FILE_ATTACHMENTS_SECTION = 'file_attachments_section';
export const EXPORT_FORM_DATA_SECTION = 'export_form_data_section';
export const VIEW_AGREEMENT_SECTION = 'view_agreement_section';
export const CREATE_TEMPLATE_SECTION = 'create_template_section';
export const PARTICIPANT_LIST_ITEM = 'participant_list_item';
export const PARTICIPANT_NAME_OR_EMAIL = 'participant_name_or_email';
export const CONTEXTUAL_ACTIONS_SECTION = 'see_more_actions';
export const REPLACE_LINK = 'replace_link';
export const REPORT_ABUSE_SECTION = 'report_abuse_section';

export const SHARE_AGREEMENT_SECTION = 'share_agreement_section';
export const SHAREE_EMAIL_FIELD = 'sharee-email-field';
export const SHAREE_MESSAGE = 'sharee_message';

export const NOTES_SECTION = 'notes_section';
export const NOTES_TEXT = 'note_text';

export const AUTOMATE_NOTIFICATIONS_SECTION = 'automate_notifications_section';
export const AUTOMATE_ARCHIVAL_SECTION = 'automate_archival_section';

export const OWNER_SECTION = 'owner_section';

export const HIDE_SHOW_SECTION = 'hide_show_section';

export const DELETE_SECTION = 'delete_section';
export const RESTORE_SECTION = 'restore_section';

export const AGREEMENT_SIGN = 'agreement_sign';
export const AGREEMENT_EDIT = 'agreement_edit';
export const AGREEMENT_MODIFY = 'agreement_modify';
export const AGREEMENT_PREFILL = 'agreement_prefill';
export const AGREEMENT_VIEW = 'agreement_view';

export const UPLOAD_SIGNED_DOCUMENT_SECTION = 'upload_signed_document_section';

// library documents
export const LIBRARY_DOCUMENT_TEMPLATE_TYPE = 'template_type';
export const LIBRARY_DOCUMENT_SHARED_WITH = 'shared_with';
export const USE_TEMPLATE_SECTION = 'use_template_section';
export const REMOVE_TEMPLATE_SECTION = 'remove_template_section';
export const REMOVE_BUTTON = 'update_state_button';

// widgets
export const WIDGET_PARTICIPANT_ROLE = 'widget_participant_role';
export const GET_CODE_WIDGET_SECTION = 'get_code_widget_section';
export const VIEW_WIDGET_CHILDREN_SECTION = 'view_widget_children_section';

// summary info
export const AGREEMENT_INFO_NAME = 'agreement_name';
export const AGREEMENT_INFO_STATUS = 'agreement_status';
export const AGREEMENT_INFO_GROUP = 'agreement_group';
export const AGREEMENT_INFO_CREATED_INFO = 'created_info';
export const AGREEMENT_INFO_SENDER = 'sender_info';
export const AGREEMENT_INFO_CC = 'cc';
export const AGREEMENT_INFO_TO = 'to';
export const AGREEMENT_INFO_SHAREES = 'sharees';
export const AGREEMENT_INFO_SENDER_MESSAGE = 'sender_message';
export const AGREEMENT_EXPIRATION = 'agreement_expiration';
export const AGREEMENT_END_DATE = 'agreement_end_date';
export const AGREEMENT_REVIEW_END_DATE = 'agreement_review_end_date';
export const AGREEMENT_PRIVATE_MESSAGE = 'agreement_privatemsg';
export const AGREEMENT_END_DATE_CALENDAR = 'agreement_end_date_calendar';
export const SEE_MORE = 'see_more';
export const SEE_MORE_ACTIONS = 'see_more_actions';

// MegaSigns
export const VIEW_MEGASIGN_CHILDREN_SECTION = 'view_megasign_children_section';
export const MEGASIGN_ACTIVITY_REPORT = 'megasign_activity_report';

// bulk actions
export const BULK_ACTIONS_DIALOG = 'bulk_actions_dialog';
