import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { setImmediate } from 'timers';
import { WithToastMessage } from 'as-ducati-core';
import Button from '@react/react-spectrum/Button';
import Wait from '@react/react-spectrum/Wait';
import LockClosed from '@react/react-spectrum/Icon/LockClosed';
import { DialogFooter, StyledParagraphBreakWord, TextInput } from 'common/styledElements';
import InputEmail from 'common/inputEmail';
import PasswordView from 'common/password';
import ExpandableToast from 'common/toast-x';
import Checkbox from '@react/react-spectrum/Checkbox';
import withUtil from 'common/withUtil';
import {
  MAX_RECIPIENT_NAME_LENGTH,
  MAX_REPLACE_MESSAGE_LENGTH,
  REPLACE_FLAVORS,
  REPLACE_PARTICIPANT_TYPE,
  Actions
} from 'stores/constants';
import TextAreaX from 'common/textAreaWithMaxLen';
import { analyticsFor } from 'utils/analytics';
import { AUTH_METHODS, isKba, isPassword, isPhone } from './authentication/editAuthentication';
import Phone from './authentication/phone';
import KbaName from './authentication/kbaName';

// analytics string
const analytics = analyticsFor(analyticsFor.MEMBERS, 'replaceParticipant');
const WIDGET_INSTANCE = 'WIDGET_INSTANCE';
const EMAIL = 'EMAIL';
const SMS = 'SMS';
const StyledLockClosed = styled(LockClosed)`
  display: inline-block;
  height: 25px;
  min-width: 35px;
  margin: 10px 10px 0 0;
`;

const AuthenticationContainer = styled.div`
  input {
    width: 100%;
    margin: 0 auto;
  }
`;

const AuthenticationInfo = styled.section`
  margin-top: 10px;
  display: flex;
  width: 90%;
`;

const ReplaceArea = styled.div`
  margin: 10px 0;
`;

const StyledFooter = styled(DialogFooter)`
  && {
    margin-top: 0px; // DCES-4318946
  }
`;

const GetAlternateRecipientName = props => (
  <TextInput
    onChange={props.onChange}
    placeholder={props.placeholder}
    maxLength={MAX_RECIPIENT_NAME_LENGTH}
  />
);

let PARTICIPANT_SET_FINISHED_STATUSES;

@inject('agreement', 'eventful', 'stores')
@observer
class ReplaceSigner extends Component {
  @observable
  authenticationReady;
  @observable
  error = null;
  @observable
  emailValue = '';
  @observable
  isAltRecipientNameInvalid = true;
  @observable
  isMessageInvalid = false;
  @observable
  phoneDeliveryInfoValue = null;
  @observable
  isValidPhoneDeliveryInfo;
  @observable
  isPrivateChecked = null;
  @observable
  isEmailDelivery;
  @observable
  isSMSDelivery;

  constructor(props) {
    super(props);
    this.trueReplace = props.view === REPLACE_FLAVORS.REPLACE_PARTICIPANT;
    this.member = this.props.member;
    this.isPrivateChecked = !this.props.stores.UserSettingsSearch.isPrivateRecipientEnabled()
      ? null
      : this.props.member.get('isPrivate') || null;
    const unresolvedBounceAvailable =
      this.props.stores.isUnresolvedBounceIndicatorFeatureAvailable();
    const isBouncedSigner =
      (unresolvedBounceAvailable &&
        this.props.agreement.events.hasBouncedEvent?.(this.member.id)) ||
      false;

    if (this.isWidgetInstance()) {
      analytics.setContext({
        replace: {
          type: props.isCC
            ? REPLACE_PARTICIPANT_TYPE.REPLACE_CC
            : REPLACE_PARTICIPANT_TYPE.REPLACE_COUNTER_SIGNER,
          bounced: isBouncedSigner,
          secureId: this.props.agreement.id
        }
      });
    } else {
      analytics.setContext({
        replace: {
          type: this.trueReplace
            ? REPLACE_FLAVORS.REPLACE_PARTICIPANT
            : REPLACE_FLAVORS.ADD_ALTERNATE_PARTICIPANT,
          bounced: isBouncedSigner,
          secureId: this.props.agreement.id
        }
      });
    }

    this.isBouncedSigner = isBouncedSigner;
    this.member = this.props.member;
    this.email = this.member.get('email');
    this.phoneDeliveryInfo = this.member.get('phoneDeliveryInfo');
    const countryCode = this.phoneDeliveryInfo && this.phoneDeliveryInfo.get('countryCode');
    if (countryCode && this.props.stores.Floodgate.hasSMSDeliveryFeatureEnabled()) {
      this.phoneDeliveryInfo.set(
        'countryCode',
        countryCode.startsWith('+') ? countryCode.substring(1) : countryCode
      );
    }
    this.optionalMessage = '';
    this.altRecipientName = '';
    this.isSMSDelivery = this.checkSMSDelivery() && this.isSMSDeliveryFeatureEnabled();
    this.isEmailDelivery = this.checkEmailDelivery();
    this.deliveryMethods = this.getDeliveryMethods();
    const authMethod = this.props.isCC
      ? null
      : this.member.get('securityOption').authenticationMethod;
    if (authMethod) {
      this.hasExistingPassword = isPassword(authMethod);
      this.hasExistingPhone = isPhone(authMethod);
      this.secOption = this.member.get('securityOption');

      this.isKbaNameRequiredAndNotWidget =
        props.stores.UserSettings.isKbaNameRequiredEnabled() && !this.isWidgetInstance();
      //DCES-4354664 - replace signer(PDS - participant defined signer/Unknown signer) option doesn't ask first name and last name if kba name required settings enabled
      //this is a safe check as nameInfo is populated only if kbaNameRequired setting is enabled
      this.isKbaNameRequiredAndWidgetPDS =
        props.stores.UserSettings.isKbaNameRequiredEnabled() &&
        this.isWidgetInstance() &&
        this.secOption &&
        this.secOption.nameInfo;
      this.recipientNameAvailable = props.stores.UserSettings.isRecipientNameAvailable();
      this.recipientNameEnabled = props.stores.UserSettings.isRecipientNameEnabled();
      this.hasExistingName =
        isKba(authMethod) &&
        (this.isKbaNameRequiredAndNotWidget || this.isKbaNameRequiredAndWidgetPDS);
      // requires authentication?
      this.authenticationReady = !(
        this.hasExistingPassword ||
        this.hasExistingPhone ||
        this.hasExistingName
      );
      if (this.isSMSDeliveryFeatureEnabled()) {
        this.authenticationReady = !(this.hasExistingPassword || this.hasExistingName);
      }
      this.passwordRef = React.createRef();
      this.phoneRef = React.createRef();
      this.nameRef = React.createRef();
    }
    this.isEmailAndSMSDelivery = this.isSMSDelivery && this.isEmailDelivery;
    if (this.isEmailAndSMSDelivery) {
      this.phoneDeliveryInfoValue = this.phoneDeliveryInfo.attributes;
    }
    // trimmedEmail will be displayed in replace recipient and alternate recipient message, along with email, handling email+sms and only sms
    this.trimmedEmail = this.email;
    if (this.isEmailAndSMSDelivery) {
      this.trimmedEmail = this.trimmedEmail + ', ' + this.phoneDeliveryInfo.getMaskedPhoneNumber();
    } else if (
      this.isSMSDelivery ||
      this.trimmedEmail.endsWith(this.props.stores.Api.Utils.SMS_SUPU_EMAIL_DOMAIN)
    ) {
      this.trimmedEmail = this.props.stores.Api.Utils.trimSmsDomain(this.email);
    }
    PARTICIPANT_SET_FINISHED_STATUSES =
      props.stores.Api.Agreements.Members.PARTICIPANT_SET_FINISHED_STATUSES;
  }

  get canChangeAuthText() {
    const { formatMessage } = this.props.stores.Intl;
    return formatMessage({ id: 'replace.existing_authentication_change' });
  }

  @action
  isWidgetInstance() {
    return this.props.agreement.get('type') === WIDGET_INSTANCE || this.props.participantSet.widget;
  }

  @action
  setError(err) {
    if (err && typeof err === 'string') {
      this.error = {
        message: err
      };
    } else {
      this.error = err;
    }
  }

  @action.bound
  setEmail(email) {
    this.emailValue = email;
  }

  /**
   * Set the new phoneDeliveryInfo of recipient to phoneDeliveryInfoValue object
   * @param phoneDeliveryInfo
   */
  @action.bound
  setPhoneDeliveryInfo(phoneDeliveryInfo) {
    this.phoneDeliveryInfoValue = phoneDeliveryInfo;
  }

  @action.bound
  handleCheckboxChange(isChecked) {
    this.isPrivateChecked = isChecked;
    this.props.member.set('isPrivate', isChecked);
  }

  @action.bound
  onReplaceSuccess(prevValue, newValue) {
    let successMsg;
    const { key, previousValue, updatedValue } = this.getSuccessMsgParameters(prevValue, newValue);

    successMsg = this.getSuccessMsg(key, previousValue, updatedValue);
    analytics.success();
    this.props.toggleOverlay(false);
    this.props.setLoading(false);
    this.props.showToast({ text: successMsg, type: 'success' });
    this.sendOnBouncedParticipationReplacedEvent();
  }

  /**
   * Send the event to manage-dropin when bounced participation is replaced and is true replace
   * and there is no more bounced events for agreement
   * The true replace means that user is not replaced with an alternative recipient
   *
   */
  sendOnBouncedParticipationReplacedEvent() {
    if (this.trueReplace && this.isBouncedSigner && this.hasNoAnotherBouncedEvents(this.member)) {
      this.props.eventful.fireActionUpdate({
        action: Actions.bouncedParticipationReplaced,
        newParticipationEmail: this.member.get('email')
      });
    }
  }

  isActiveParticipant(participantId) {
    return this.props.agreement.members.findParticipant(participantId).get('status') !== 'REPLACED';
  }

  /**
   * Has no another bounced events for active paticipations in agreement
   *
   * @param {object} member The replaced user
   * @returns true if there isn't bounced event for active participations, false otherwise
   */

  hasNoAnotherBouncedEvents(member) {
    const bouncedEvents = this.props.agreement.events.models.filter(n => {
      return (
        n.get('participantId') !== member.id &&
        n.get('participantRole') !== 'CC' &&
        n.get('participantRole') !== 'RESOURCE_SHARE' &&
        n.get('type') === 'EMAIL_BOUNCED' &&
        this.isActiveParticipant(n.get('participantId'))
      );
    });
    for (const bouncedEvent of bouncedEvents) {
      const pSet = this.getParticipantSet(bouncedEvent.get('participantId'));
      if (pSet && !PARTICIPANT_SET_FINISHED_STATUSES.hasOwnProperty(pSet.get('status'))) {
        return false;
      }
    }
    return true;
  }

  /**
   * Helper function for getting the parameters for success message
   * @param previousValue
   * @param newValue
   * @returns { key: string, previousValue: string|null, updatedValue: string }
   */
  getSuccessMsgParameters(previousValue, newValue) {
    if (this.trueReplace) {
      return this.getParametersForTrueReplace(previousValue, newValue);
    } else {
      return this.getParametersForAlternateParticipant(newValue);
    }
  }

  /**
   * Find participantSet for given participantId
   * @param {String} participantId
   */
  getParticipantSet(participantId) {
    return this.props.agreement.members
      .get('participantSets')
      .models.find(m =>
        m.get('memberInfos').models.find(member => member.get('id') === participantId)
      );
  }

  /**
   * Return the parameters for success message for alternate participant flow
   * @param newValue
   * @returns {key: String, previousValue: null, updatedValue: String}
   */
  getParametersForAlternateParticipant(newValue) {
    return {
      key: 'replace.alternate_participant_success',
      previousValue: null,
      updatedValue: newValue || this.emailValue || this.getPhoneNumber(this.phoneDeliveryInfoValue)
    };
  }

  /**
   * Return the parameters for success message for replace recipient flow
   * @param prevValue
   * @param newValue
   * @returns {key: string, previousValue: string, updatedValue: string}
   */
  getParametersForTrueReplace(prevValue, newValue) {
    // If the delivery method is limited to SMS, the email will contain the phone number followed by "@sms.adobesign.com," which is the SUPU SMS domain.
    let previousEmail = this.member.previous('email');
    return {
      key: 'replace.success',
      previousValue:
        prevValue ||
        this.props.stores.Api.Utils.trimSmsDomain(
          previousEmail.startsWith('+') ? previousEmail.substring(1) : previousEmail
        ),
      updatedValue: newValue || this.emailValue || this.getPhoneNumber(this.phoneDeliveryInfoValue)
    };
  }

  /**
   * @param msgId
   * @param originalEmailValue
   * @param newEmailValue
   * @returns {String} The success message to be displayed after the replace is successful
   */
  getSuccessMsg(msgId, originalEmailValue, newEmailValue) {
    const { formatMessage } = this.props.stores.Intl;
    return formatMessage(
      { id: msgId },
      {
        originalEmail: originalEmailValue,
        newEmail: newEmailValue
      }
    );
  }

  @action.bound
  onReplaceFailure(err) {
    analytics.failed(err);
    this.props.setLoading(false);

    // reset to old values as save was not successful
    if (this.trueReplace) {
      this.member.set({
        email: this.member.previous('email'),
        name: this.member.previous('name'),
        privateMessage: this.member.previous('privateMessage'),
        phoneDeliveryInfo: this.member.previous('phoneDeliveryInfo'),
        isPrivate: this.isPrivateChecked
      });
    }
    // reset to the old private message of the ParticipantSet as save was not successful.
    this.updatePrivateMessageIfNeeded(this.props.participantSet.previous('privateMessage'));
  }

  /**
   * Handler to update the optional private message on key up
   * @param msg {string}
   * @param o {Object} contains boolean isInvalid
   */
  @action
  onMessageChange(msg, { isInvalid }) {
    this.optionalMessage = msg;
    this.isMessageInvalid = isInvalid;
  }

  /**
   * Handler to update recipient name
   * @param name {string}
   * @param o {Object} contains boolean isInvalid
   */
  @action
  onAltRecipientNameChange(name) {
    this.altRecipientName = name;
    this.isAltRecipientNameInvalid = this.altRecipientName.trim() === '';
  }

  /**
   * User has clicked the replace button. Do the api call, handle success and errors
   */
  @action
  onClickReplace() {
    const participantSet = this.props.participantSet;
    let newSecurityOption;

    if (this.hasExistingPassword) {
      let passwordInstance = this.passwordRef.current.wrappedInstance;
      if (passwordInstance.validate()) {
        this.authenticationReady = false;
        return;
      }
      newSecurityOption = {
        authenticationMethod: AUTH_METHODS.PASSWORD,
        password: passwordInstance.getPassword()
      }; // new password
    } else if (this.hasExistingPhone) {
      let phoneInstance = this.isSMSDelivery
        ? this.phoneView.wrappedInstance
        : this.phoneRef.current.wrappedInstance;
      if (phoneInstance.validate()) {
        this.authenticationReady = false;
        return;
      }
      newSecurityOption = {
        authenticationMethod: AUTH_METHODS.PHONE,
        phoneInfo: phoneInstance.getValues()
      }; // new phone values
    } else if (this.hasExistingName) {
      let nameInstance = this.nameRef.current.wrappedInstance;
      if (nameInstance.hasErrors()) {
        this.authenticationReady = false;
        return;
      }
      newSecurityOption = {
        authenticationMethod: AUTH_METHODS.KBA,
        nameInfo: nameInstance.getValues()
      }; // new first and last name values
    }

    if (this.isSMSDelivery) {
      this.validatePhoneDeliveryInfo(this.isValidPhoneDeliveryInfo);
      if (this.error) return;
    }
    if (this.isEmailDelivery) {
      this.validateEmail(this.emailValue);
      if (this.error) return;
    }

    this.setError(null);
    this.error = null;
    this.props.setLoading(true);

    if (!this.props.isCC) {
      let memberInfos = participantSet.get('memberInfos').clone();
      if (this.hasExistingName) {
        this.altRecipientName =
          newSecurityOption.nameInfo.firstName + ' ' + newSecurityOption.nameInfo.lastName;
      }

      if (this.trueReplace) {
        // order matters -- set the security option first so the .previous attributes
        // for the member retains the email DCES-4266062
        if (newSecurityOption) {
          this.member.set({ securityOption: newSecurityOption });
        }

        // Replace current signer
        // ORIGINAL_SIGNER_CANNOT_PARTICIPATE - replace the signer in memberInfos
        // with the status !== 'REPLACED'. (only one should have the a status)
        this.member.set({
          email: this.emailValue,
          name: this.altRecipientName,
          privateMessage: this.optionalMessage,
          phoneDeliveryInfo: this.phoneDeliveryInfoValue,
          isPrivate: this.isPrivateChecked
        });

        const privateMessage = this.optionalMessage === '' ? null : this.optionalMessage;
        // For widgets, update the private message at participantSet also.
        // Since private message is associated at participantSet level, we are updating it in participantSet.
        this.updatePrivateMessageIfNeeded(privateMessage);
      } else {
        // Add alternate signer
        // ORIGINAL_SIGNER_CAN_PARTICIPANTE - add participant to memberInfos
        memberInfos.add({
          email: this.emailValue,
          name: this.altRecipientName,
          securityOption: newSecurityOption || {
            authenticationMethod: AUTH_METHODS.NONE
          },
          privateMessage: this.optionalMessage,
          phoneDeliveryInfo: this.phoneDeliveryInfoValue,
          isPrivate: this.isPrivateChecked
        });
      }

      // Need to pass change attributes directly to save()
      // so that change event is fired
      participantSet
        .save({ memberInfos: memberInfos.toJSON() }, { wait: true })
        .then(() => {
          this.onReplaceSuccess();
          // fetch members to get names (if available) and update observable
          this.props.agreement.members.fetch();
          // fetch participantSet again to get new member's ID for further edits
          participantSet.fetch({ silent: true });
          this.props.agreement.events.fetch();
          this.props.agreement.reminders.fetch({ data: { status: 'ACTIVE,COMPLETE' } });
          this.props.agreement.shares.fetch();
        })
        .catch(err => {
          this.setError(err);
          this.onReplaceFailure(err);
        });
    } else {
      const ccsInfo = this.props.agreement.members.ccsInfo;
      const ccInfoList = this.props.agreement.members.get('ccsInfo');
      const em = this.email,
        changedVal = this.emailValue;
      ccInfoList.forEach(d => {
        if (d.get('email') === em) {
          d.set('email', changedVal);
        }
        d.unset('company');
        d.unset('name');
        d.unset('participantId');
        d.unset('self');
      });

      // Need to pass change attributes directly to save()
      // so that change event is fired
      ccsInfo
        .save({ ccInfoList: ccInfoList.toJSON() }, { wait: true })
        .then(() => {
          this.member.set({
            email: changedVal
          });
          this.onReplaceSuccess(em, changedVal);
          this.props.agreement.members.fetch();
          this.props.agreement.events.fetch();
        })
        .catch(err => {
          this.setError(err);
          this.onReplaceFailure(err);
        });
    }
  }

  /**
   * Any change to the authentication - callback to toggle the 'ready' observable
   * @param {boolean} isValid - auth is valid
   */
  onChangeAuthentication(isValid) {
    this.authenticationReady = isValid; // doesn't allow clicking on 'Replace' button until there's something in the inputs for the authentication
  }

  /**
   * Any change to the InputEmail
   * Validates the provided email address and sets error messages based on various conditions.
   * @param {string} email - InputEmail
   * @description
   * This method checks the provided email against various conditions and sets appropriate error messages if any of the conditions are met:
   * - For combined Email+SMS delivery, an error is thrown if the email and phone delivery info are the same as the old recipient.
   * - For 'Only Email' delivery, an error is thrown if the email is the same as the old recipient.
   * - If the entered email is null, empty, or ends with '@sms.adobesign.com', an error indicating 'please enter valid email' is thrown.
   * - If the context is CC and the email is a duplicate of an existing email, an error is thrown.
   * @returns {void}
   */
  @action
  validateEmail(email) {
    const { formatMessage } = this.props.stores.Intl;
    if (this.isBothEmailAndSMSDelivery() && this.isSameEmailAndPhoneInfo(email)) {
      this.setError(formatMessage({ id: 'replace.error.same_email_and_phone_number' }));
      return;
    }
    if (this.isOnlyEmailDelivery() && this.isSameEmail(email)) {
      return this.showErrMsgForSameEmail();
    }
    if (this.isInvalidEmail(email)) {
      return this.showErrMsgForInvalidEmail();
    }
    if (this.props.isCC && this.isDuplicateEmail(email.toLowerCase())) {
      this.setError(formatMessage({ id: 'replace.error.same_email_listed' }));
      return;
    }
    this.setError(null);
  }

  isBothEmailAndSMSDelivery() {
    return (
      this.isEmailDelivery &&
      this.isSMSDelivery &&
      this.deliveryMethods.includes(EMAIL) &&
      this.deliveryMethods.includes(SMS)
    );
  }

  isSameEmailAndPhoneInfo(email) {
    return this.isSameEmail(email) && this.isSamePhoneInfo(this.phoneDeliveryInfoValue);
  }

  isOnlyEmailDelivery() {
    return this.isEmailDelivery && !this.isSMSDelivery && !this.deliveryMethods.includes(SMS);
  }

  isInvalidEmail(email) {
    return !email || (email && email.endsWith(this.props.stores.Api.Utils.SMS_SUPU_EMAIL_DOMAIN));
  }

  showErrMsgForSameEmail() {
    const { formatMessage } = this.props.stores.Intl;
    if (!this.isSMSDeliveryFeatureEnabled()) {
      return formatMessage({ id: 'replace.error.same_email' });
    }
    this.setError(formatMessage({ id: 'replace.error.same_email' }));
  }

  showErrMsgForInvalidEmail() {
    const { formatMessage } = this.props.stores.Intl;
    if (!this.isSMSDeliveryFeatureEnabled()) {
      return formatMessage({ id: 'replace.valid_email' });
    }
    this.setError(formatMessage({ id: 'replace.valid_email' }));
  }

  /**
   * Any change to the phoneDeliveryInfo - callback to toggle the 'isValidPhoneDeliveryInfo' observable
   * Validates the provided phone delivery information and sets error messages based on various conditions.
   * @param {boolean} isValid - phoneDeliveryInfo is valid
   * @description
   * This method checks the provided Phone Delivery Information against various conditions and sets appropriate error messages if any of the conditions are met:
   * - For 'Email+SMS' delivery, an error is thrown if the email and phone delivery info are the same as the old recipient.
   * - For 'Only SMS' delivery, an error is thrown if the phone delivery info is the same as the old recipient.
   * @returns {void}
   */
  @action
  validatePhoneDeliveryInfo(isValid) {
    this.isValidPhoneDeliveryInfo = isValid;
    const phoneDeliveryInstance = this.phoneView.wrappedInstance;
    const phoneInfo = phoneDeliveryInstance.getValues();
    const phoneDeliveryInfo = new this.props.stores.Api.Helpers.PhoneDeliveryInfo(phoneInfo);
    // phoneInfo.countryCode is auto converted to a number and we need a string, hence the conversion is done
    phoneDeliveryInfo.set('countryCode', phoneInfo.countryCode.toString());
    this.setPhoneDeliveryInfo(phoneDeliveryInfo);

    const { formatMessage } = this.props.stores.Intl;
    const isSameEmail = this.isSameEmail(this.emailValue);
    const isSamePhoneInfo = this.isSamePhoneInfo(this.phoneDeliveryInfoValue);
    let hasSMSDelivery = this.isSMSDelivery && this.deliveryMethods.includes(SMS);
    let isSameEmailAndPhoneInfo = isSameEmail && isSamePhoneInfo;
    let isOnlySMSDelivery =
      hasSMSDelivery && !this.isEmailDelivery && !this.deliveryMethods.includes(EMAIL);
    let isEmailAndSMSDelivery =
      hasSMSDelivery && this.isEmailDelivery && this.deliveryMethods.includes(EMAIL);
    if (isEmailAndSMSDelivery && isSameEmailAndPhoneInfo) {
      // For 'Email+SMS' delivery, throw error if email and phoneDeliveryInfo are same as the old recipient
      this.setError(formatMessage({ id: 'replace.error.same_email_and_phone_number' }));
      return;
    }
    if (isOnlySMSDelivery && isSamePhoneInfo) {
      // For 'Only SMS' delivery, throw error if phoneDeliveryInfo is same as the old recipient
      this.setError(formatMessage({ id: 'replace.error.same_phone_number' }));
      return;
    }
    this.setError(null);
  }

  /**
   * Returns true if the new phoneDeliveryInfo matches with the old recipient's phoneDeliveryInfo
   * @param phoneInfo
   * @returns {boolean}
   */
  isSamePhoneInfo(newPhoneInfo) {
    return (
      newPhoneInfo &&
      this.props.stores.Api._.isEqual(newPhoneInfo.attributes, this.phoneDeliveryInfo.attributes)
    );
  }

  /**
   * Returns true if the new email matches with the old recipient's email
   * @param newEmail
   * @returns {boolean}
   */
  isSameEmail(newEmail) {
    return newEmail && newEmail.toLowerCase() === this.email.toLowerCase();
  }

  getNewPassword() {
    if (!this.hasExistingPassword) return;
    const { formatMessage } = this.props.stores.Intl,
      existingPasswordSummary = formatMessage(
        { id: 'replace.existing_authentication' },
        {
          email: this.trimmedEmail,
          authenticationType: AUTH_METHODS.PASSWORD.toLowerCase()
        }
      );
    return (
      <Fragment>
        <AuthenticationInfo>
          <StyledLockClosed size={'L'} />
          <StyledParagraphBreakWord>
            {existingPasswordSummary} {this.canChangeAuthText}
          </StyledParagraphBreakWord>
        </AuthenticationInfo>
        <AuthenticationContainer>
          <PasswordView ref={this.passwordRef} onValidate={s => this.onChangeAuthentication(s)} />
        </AuthenticationContainer>
      </Fragment>
    );
  }

  getNewPhone() {
    if (!this.hasExistingPhone) return;
    const { formatMessage } = this.props.stores.Intl,
      existingPhoneSummary = formatMessage(
        {
          id: 'replace.existing_authentication'
        },
        {
          email: this.trimmedEmail,
          authenticationType: AUTH_METHODS.PHONE.toLowerCase()
        }
      );

    return (
      <Fragment>
        <AuthenticationInfo>
          <StyledLockClosed size={'L'} />
          <StyledParagraphBreakWord>
            {existingPhoneSummary} {this.canChangeAuthText}
          </StyledParagraphBreakWord>
        </AuthenticationInfo>
        <AuthenticationContainer>
          {!this.isSMSDelivery && (
            <Phone ref={this.phoneRef} onValidate={s => this.onChangeAuthentication(s)} />
          )}
        </AuthenticationContainer>
      </Fragment>
    );
  }

  isDuplicateEmail(email) {
    const ccInfoList = this.props.agreement.members.get('ccsInfo').models;
    for (var i = 0; i < ccInfoList.length; i++) {
      if (ccInfoList[i].get('email').toLowerCase() === email) return true;
    }
    const counterSignerSet = this.props.agreement.members.get('additionalParticipantSets').models;
    for (i = 0; i < counterSignerSet.length; i++) {
      if (counterSignerSet[i].get('memberInfos').models[0].get('email').toLowerCase() === email)
        return true;
    }
    return false;
  }

  /**
   * Returns true for 'Email+SMS' or 'Only SMS' delivery
   * @returns {boolean}
   */
  checkSMSDelivery() {
    return this.phoneDeliveryInfo && !this.phoneDeliveryInfo.isEmpty();
  }

  /**
   * Returns true for 'Email' or 'Email+SMS' delivery
   * @returns {boolean}
   */
  checkEmailDelivery() {
    return !!(
      this.email && !this.email.endsWith(this.props.stores.Api.Utils.SMS_SUPU_EMAIL_DOMAIN)
    );
  }

  /**
   * Returns [EMAIL, SMS] for 'EMAIL+SMS' delivery
   * @returns {Array of delivery methods}
   */
  getDeliveryMethods() {
    const deliveryMethods = [];
    if (this.isEmailDelivery) {
      deliveryMethods.push(EMAIL);
    }
    if (this.isSMSDelivery) {
      deliveryMethods.push(SMS);
    }
    return deliveryMethods;
  }

  /**
   * Enable the 'Replace' button if the inputs are valid as per the recipient's delivery mechanism
   */
  shouldEnableReplaceForValidDelivery() {
    // If the floodgate setting for SMS is OFF, then 'Replace' button is toggled based on Email input
    if (!this.isSMSDeliveryFeatureEnabled()) {
      return !!this.emailValue;
    }
    if (this.isEmailDelivery && !this.emailValue) {
      return false;
    }
    if (this.isSMSDelivery && !this.isValidPhoneDeliveryInfo) {
      return false;
    }
    return true;
  }

  checkIsWitness(role) {
    return role === this.props.stores.Api.Helpers.Members.ROLE.WITNESS;
  }

  checkIsNotary(role) {
    return role === this.props.stores.Api.Helpers.Members.ROLE.NOTARY_SIGNER;
  }

  checkIsDefinedWitness(role) {
    return this.checkIsWitness(role) && this.member && this.member.get('email');
  }

  showEmailInput() {
    return this.isSMSDeliveryFeatureEnabled()
      ? this.isEmailDelivery || this.props.isSignerWithWitness
      : true;
  }

  // For Witness and Notory Roles SMS delivery is not supported
  shouldRenderDeliveryCheckbox(isDefinedWitness, isNotary) {
    return (
      this.isSMSDeliveryFeatureEnabled() &&
      this.props.agreement.get('workflowId') === null &&
      !this.isWidgetInstance() &&
      !isDefinedWitness &&
      !isNotary
    );
  }

  getPrefilledEmailValue() {
    if (this.isEmailDelivery && this.isSMSDeliveryFeatureEnabled()) {
      return this.email;
    }
    return null;
  }

  /**
   * returns the formatted phone number for given phoneDeliveryInfo object
   * @param phoneDeliveryInfo
   * @returns {String}
   */
  getPhoneNumber(phoneDeliveryInfo) {
    return phoneDeliveryInfo.getFormattedString();
  }

  /**
   * Return true if the floodgate setting for SMS is enabled
   * @returns {boolean}
   */
  isSMSDeliveryFeatureEnabled() {
    return (
      this.props.stores.Floodgate.hasSMSDeliveryFeatureEnabled() &&
      this.props.stores.UserSettingsSearch.isSmsDeliveryEnabled()
    );
  }

  /**
   * Return true if the floodgate setting for private recipient is enabled
   * @returns {boolean}
   */
  privateRecipientEnabled() {
    return (
      this.props.stores.Floodgate.hasPrivateRecipientFeatureEnabled() &&
      this.props.stores.UserSettingsSearch.isPrivateRecipientEnabled()
    );
  }

  getTextArea(messagePlaceHolder) {
    // We're not allowing widgets group members to update the private message for individual member.
    // Since private message is associated at participantSet level (i.e same for all group members).
    if (this.props.isWidget && this.props.isGroupMember) {
      return null;
    }

    return (
      <TextAreaX
        className="optional_message"
        height="70px"
        onChange={this.onMessageChange.bind(this)}
        placeholder={messagePlaceHolder}
        maxLength={MAX_REPLACE_MESSAGE_LENGTH}
      />
    );
  }

  getNewName() {
    if (!this.hasExistingName) return;
    const { formatMessage } = this.props.stores.Intl,
      existingNameSummary = formatMessage(
        {
          id: 'replace.existing_authentication'
        },
        {
          email: this.trimmedEmail,
          authenticationType: AUTH_METHODS.KBA.toLowerCase()
        }
      );

    return (
      <Fragment>
        <AuthenticationInfo>
          <StyledLockClosed size={'L'} />
          <StyledParagraphBreakWord>
            {existingNameSummary} {this.canChangeAuthText}
          </StyledParagraphBreakWord>
        </AuthenticationInfo>
        <AuthenticationContainer>
          <KbaName ref={this.nameRef} onChangeHandler={s => this.onChangeAuthentication(s)} />
        </AuthenticationContainer>
      </Fragment>
    );
  }

  /**
   * This method update the private message in ParticipantSet but only when it's a webform participant and doesn't belong to any group.
   * Since private message is associated at participantSet level (i.e same for all group members) hence not updating for group member participant.
   * @param privateMessage
   */
  @action
  updatePrivateMessageIfNeeded(privateMessage) {
    if (this.props.isWidget && !this.props.isGroupMember && !this.props.isCC) {
      this.props.participantSet.set({ privateMessage: privateMessage });
    }
  }

  @action
  updateEmailDelivery() {
    this.setError(null);
    if (this.isSMSDelivery && this.isEmailDelivery) {
      this.emailValue = null;
    }
    if (!this.isEmailDelivery) {
      this.emailValue = null;
    }
    if (this.isEmailDelivery && !this.isSMSDelivery) {
      this.isEmailDelivery = !this.isEmailDelivery;
    }
    this.isEmailDelivery = !this.isEmailDelivery;
  }

  @action
  updateSmsDelivery() {
    this.setError(null);
    if (this.isSMSDelivery && this.isEmailDelivery) {
      this.phoneDeliveryInfoValue = null;
    }
    if (!this.isSMSDelivery) {
      this.phoneDeliveryInfoValue = null;
    }
    if (this.isSMSDelivery && !this.isEmailDelivery) {
      this.isEmailDelivery = !this.isEmailDelivery;
    }
    this.isSMSDelivery = !this.isSMSDelivery;
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    // Screen readers are not able to read the content inside of explicit HTML tags in the middle of text
    const role = this.props.participantSet.get('role');
    const isNotary = this.checkIsNotary(role);
    const isDefinedWitness = this.checkIsDefinedWitness(role);
    const replaceText =
      this.recipientNameEnabled || isDefinedWitness
        ? formatMessage({ id: 'replace.summary_with_name_required' }, { email: this.trimmedEmail })
        : formatMessage({ id: 'replace.summary' }, { email: this.trimmedEmail });
    const replaceSummary = this.trueReplace
      ? replaceText
      : formatMessage({ id: 'replace.alternate_participant_summary' });
    const replaceRecipientDeliverySummary = formatMessage({ id: 'replace_recipient.delivery' });
    const emailCheckboxLabel = formatMessage({ id: 'delivery.email' });
    const phoneCheckboxLabel = formatMessage({ id: 'delivery.phone' });
    const onClickReplace = this.onClickReplace.bind(this);
    const replaceButtonText = this.trueReplace
        ? formatMessage({ id: 'replace.title' })
        : formatMessage({ id: 'actions.add' }),
      emailLabel = formatMessage({ id: 'common.enter_email' }),
      recipientNamePlaceholder = formatMessage({ id: 'replace.recipient_name_placeholder' }),
      messagePlaceHolder =
        '(' +
        formatMessage({ id: 'optional' }) +
        ') ' +
        formatMessage({ id: 'replace.message_placeholder' });

    let authReady = this.props.isCC ? false : !this.authenticationReady;
    if (authReady && this.isSMSDeliveryFeatureEnabled() && this.hasExistingPhone) {
      authReady = false;
    }

    // If the SMS setting is enabled then show the email input as per the delivery method else show it always
    const showEmailInput = this.showEmailInput();
    const shouldRender = this.shouldRenderDeliveryCheckbox(isDefinedWitness, isNotary);
    let prefilledEmailValue = this.getPrefilledEmailValue();
    return (
      <Fragment>
        {this.props.loading && <Wait centered />}
        {replaceSummary}
        {shouldRender && (
          <Fragment>
            <br />
            <div>{replaceRecipientDeliverySummary}</div>
            <Checkbox
              label={emailCheckboxLabel}
              defaultChecked={this.isEmailDelivery || this.props.isSignerWithWitness}
              value={this.isEmailDelivery || this.props.isSignerWithWitness}
              checked={this.isEmailDelivery || this.props.isSignerWithWitness}
              disabled={
                this.secOption.authenticationMethod === AUTH_METHODS.EMAIL_OTP ||
                this.secOption.authenticationMethod === AUTH_METHODS.ADOBE_SIGN ||
                this.props.isSignerWithWitness
              }
              onClick={() => this.updateEmailDelivery()}
            />
            <Checkbox
              label={phoneCheckboxLabel}
              defaultChecked={this.isSMSDelivery}
              value={this.isSMSDelivery}
              checked={this.isSMSDelivery}
              onClick={() => this.updateSmsDelivery()}
            />
          </Fragment>
        )}
        {showEmailInput && (
          <InputEmail
            autoFocus
            className="replaced_email"
            aria-label={emailLabel}
            placeholder={emailLabel}
            validate={email => !this.isSMSDeliveryFeatureEnabled() && this.validateEmail(email)}
            value={prefilledEmailValue}
            onValid={email => setImmediate(() => this.setEmail(email))}
            // onComplete={onClickReplace}
            onEnter={onClickReplace}
          />
        )}
        {this.isSMSDeliveryFeatureEnabled() && this.isSMSDelivery && (
          <Phone phoneInfo={this.phoneDeliveryInfo.attributes} ref={el => (this.phoneView = el)} />
        )}
        {((this.recipientNameAvailable && this.recipientNameEnabled && !this.hasExistingName) ||
          isDefinedWitness) && (
          <GetAlternateRecipientName
            placeholder={recipientNamePlaceholder}
            onChange={name => this.onAltRecipientNameChange(name)}
          />
        )}
        {this.getNewPassword()}
        {this.getNewPhone()}
        {this.getNewName()}
        {this.error && (
          <ExpandableToast
            variant="error"
            compact
            closable
            message={this.error.message}
            details={this.error.origMessage}
            onClose={() => this.setError(null)}
          />
        )}
        {this.getTextArea(messagePlaceHolder)}
        {this.privateRecipientEnabled() && this.trueReplace && !this.props.isGroupMember && (
          <Checkbox
            defaultChecked={this.props.member.get('isPrivate')}
            onChange={this.handleCheckboxChange}
          >
            {formatMessage({ id: 'replace.checkbox_title' })}
          </Checkbox>
        )}
        <ReplaceArea>
          <StyledFooter>
            <Button
              disabled={this.props.loading}
              quiet
              variant="secondary"
              onClick={() => this.props.toggleOverlay(false)}
            >
              {formatMessage({ id: 'cancel.title' })}
            </Button>
            <Button
              disabled={
                this.props.loading ||
                !this.shouldEnableReplaceForValidDelivery() ||
                authReady ||
                this.isMessageInvalid ||
                (this.recipientNameAvailable &&
                  this.recipientNameEnabled &&
                  !this.hasExistingName &&
                  this.isAltRecipientNameInvalid) ||
                (isDefinedWitness && this.isAltRecipientNameInvalid)
              }
              variant="primary"
              onClick={onClickReplace}
            >
              {replaceButtonText}
            </Button>
          </StyledFooter>
        </ReplaceArea>
      </Fragment>
    );
  }
}

export default WithToastMessage(withUtil(ReplaceSigner));
