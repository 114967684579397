import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { WithToastMessage } from 'as-ducati-core';
import ExpandableTitle from 'common/ExpandableTitle';
import withErrorBoundary from 'common/withErrorBoundary';
import { analyticsFor } from 'utils/analytics';
import { CONTEXT_BOARD_TYPES, MEMBER_EVENTS_TO_DISPLAY } from 'stores/constants';
import * as classNames from './classNames';
import SingleMember from './member.js';
import Group from './group';

const MAX_MEMBERS_SHOWN = 50; // some large number...we show all mainly all members (previously capped at 3)

// members with these states get a checkmark
const MEMBER_DONE_STATUSES = ['COMPLETED', 'WAITING_FOR_OTHERS'];

const analytics = analyticsFor(analyticsFor.MEMBERS);

const NumMember = styled.span`
  padding-right: 4px;
`;

const List = styled.ul`
  position: relative;
  padding: 0 6px; /* matches padding of surrounding spectrum buttons */
  display: inline-block;
  width: 100%;
  && {
    margin: 0.6em 0; /* undo monolith style */
  }
`;

const Hybrid = styled.ul`
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  list-style: none;
  padding: 0px;

  // Bumps specificity
  &&& > li::after {
    width: 0;
  }
`;

/*
  This is the border that wraps hybrid members
 */
const HybridWrapper = styled.div`
  border: 2px solid rgb(179, 179, 179);
  width: 22px;
  border-radius: 25px;
  margin-left: -2px;
  margin-top: -3px;
  position: absolute;
  height: ${props => props.numHybrid * 40 - 14}px;
  z-index: 1;
`;

// https://github.com/styled-components/styled-components/issues/896 for clarity and testing
Hybrid.displayName = 'Hybrid';

// in popover on member hover
const MemberInfo = styled.div`
  margin: 5px 0;

  span:first-child {
    font-weight: bold;
  }
`;

MemberInfo.displayName = 'MemberInfo';

/**
 * Counts total number of participants that have completed their action (with status equal to MEMBER_DONE_STATUSES)
 * @return {int} number of participants 'done'
 */
const countDone = participantSets => {
  const done = participantSets.filter(pSet => {
    return MEMBER_DONE_STATUSES.includes(pSet.get('status'));
  });
  return done.length;
};

const isWidget = type => type === CONTEXT_BOARD_TYPES.WIDGET;

const MembersHeader = props => {
  const { message } = props.stores.agreement.members.lastError || {};
  return (
    <ExpandableTitle
      {...props}
      onClick={() => {
        if (!props.expanded) analytics.clicked();
        if (props.onClick) props.onClick();
      }}
      title={<MembersTitle {...props} />}
      error={message}
    />
  );
};

const MembersTitle = props => {
  const { formatMessage } = props.stores.Intl;
  const error = props.agreement.members.lastError;
  const participantSets = props.participantSets;
  const isTerminated = props.isTerminated;
  const memberCount = error ? 2 /* dummy to get plural */ : participantSets.length;
  const completedCount = countDone(participantSets);
  const isMSWFEnabled = props.stores.UserSettings.isMultiSignerFeatureEnabled();
  const isMWFInFlightEnabled = props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled();
  const renderNumberCount =
    isMSWFEnabled || isMWFInFlightEnabled ? null : (
      <NumMember className={classNames.MEMBER_COUNT}>{!error && memberCount}</NumMember>
    );
  return (
    <Fragment>
      {props.isCC ? null : renderNumberCount}

      {isWidget(props.type) ? (
        isMSWFEnabled || isMWFInFlightEnabled ? (
          <b>
            {' '}
            {props.isCC && isMWFInFlightEnabled
              ? formatMessage({ id: 'widget.cc_participant_headers' })
              : formatMessage({ id: 'widget.participant_headers' })}
          </b>
        ) : (
          formatMessage({
            id: memberCount > 1 ? 'widget.countersigners_plural' : 'widget.countersigners_singular'
          })
        )
      ) : (
        <Fragment>
          {formatMessage({
            id: memberCount > 1 ? 'participants.title_plural' : 'participants.title_single'
          })}
          {!isTerminated && (
            <span>{` (${completedCount} ${formatMessage({ id: 'events.completed' })})`}</span>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

/**
 * Display members (list of participant sets) of an agreement, widget, etc.
 *
 * @prop participantSets {Backbone.Collection<ParticipantSet>} a ParticipantSet is a Backbone
 *    model with attributes: order, status, id, role, privateMessage, and memberInfos etc.
 * @prop workflow {string} signing order
 * @prop isCompleted {boolean} if agreement is complete
 * @prop isTerminated {boolean} if agreement has been terminated (expired, cancelled, etc.)
 * @prop type {string} agreement type
 * @prop expanded {boolean} if section should be expanded by default
 * @prop onClick {function} on click handler
 */
@inject('agreement', 'eventful', 'stores')
@observer
class Members extends Component {
  get participantSets() {
    return this.props.participantSets.filter(pSet => {
      return pSet.get('role') !== 'SHARE';
    });
  }

  constructor(props) {
    super(props);
    this.observable = this.props.stores.getObservableModel(this.props.agreement.events);
  }

  isParallelWorkflow(workflow) {
    return workflow === this.props.stores.Api.Agreements.Members.WORKFLOW.PARALLEL;
  }

  componentDidUpdate() {
    this.props.eventful.fireUpdate({ component: 'members' });
  }

  /**
   * get all the witness participants
   *
   * @returns {*}
   */
  getWitnessParticipants() {
    return this.props.participantSets.filter(pSet => {
      return pSet.get('role') && pSet.get('role') === 'WITNESS';
    });
  }

  /**
   * Give filtered list of witness for current signer
   *
   * @param signer
   * @returns {Generator<*, {isEmpty}|*, *>}
   */
  getWitnessOfSigner(signer) {
    return this.getWitnessParticipants().filter(witness => {
      return (
        witness.get('providerParticipationInfo') &&
        witness.get('providerParticipationInfo').participationSetId === signer.get('id')
      );
    });
  }

  /**
   *
   * @param participantList {Backbone.Collection}
   * @returns {Object} members JSX, takes care of special cases such as hybrid and groups as well
   * @private
   */
  getMembersView(participantList) {
    let lastOrder = 0,
      fullArray = [],
      membersArray = [],
      parallelWorkflow = this.isParallelWorkflow(this.workflow),
      restartIndex = 0,
      Comp;

    // Filter out any events that happen before a RESTART, and then group the remaining events by participant id, with participantId
    // being the key and the values being an array of the events associated with that participant, e.g.:  {pid1: [event1, event2]}
    restartIndex = this.props.stores.agreement.events.reduce(
      (restartIndex, currentElement, currentIndx) =>
        currentElement.get('type') === 'RESTARTED' ? currentIndx : restartIndex,
      0
    );
    this.groupedEvents = this.props.stores.agreement.events.groupBy((event, index) => {
      if (restartIndex <= index && MEMBER_EVENTS_TO_DISPLAY.includes(event.get('type'))) {
        return event.get('participantId');
      }
      return undefined;
    });

    const addToMembersArray = (pSet, context) => {
      let memberEvents, singleMember;
      if (!this.props.isCC) {
        let activeMembers = pSet.getActiveMembers();
        if (activeMembers.length > 1) {
          // is group
          Comp = Group;
        } else {
          Comp = SingleMember;
          // get event associated with single Member
          singleMember = activeMembers[0]; // if not a group, the member will always be the first / only returned from getActiveMembers
          this.isSignerWithWitness = this.getWitnessOfSigner(pSet).length > 0;
          memberEvents = this.groupedEvents && this.groupedEvents[singleMember.get('id')];
        }

        // DCES-4473975: Pass down all memberEvents as props below since we will look for the presence of both
        // ACTION_COMPLETED* and *BOUNCED events to determine whether or not to show the visual clue.
        membersArray.push(
          <Comp
            key={pSet.cid}
            workflow={this.workflow}
            participantSet={pSet}
            groupedEvents={this.groupedEvents}
            type={this.props.type}
            isDCWeb={this.props.isDCWeb}
            isSignerWithWitness={this.isSignerWithWitness}
            memberEvents={memberEvents} // pass all memberEvents as props
          />
        );
      } else {
        Comp = SingleMember;
        singleMember = pSet;
        // get event associated with single Member
        memberEvents = this.groupedEvents && this.groupedEvents[singleMember.get('id')];
        membersArray.push(
          <Comp
            key={pSet.cid}
            workflow={this.workflow}
            participantSet={pSet}
            groupedEvents={this.groupedEvents}
            type={this.props.type}
            isDCWeb={this.props.isDCWeb}
            isCC={this.props.isCC}
            // (if the user has completed, that will always be the last event)
          />
        );
      }
    };

    // go through list of participants and add to membersArray (for hybrid case) or to fullArray
    // returns the fullArray jsx
    participantList.forEach((pSet, i) => {
      if (parallelWorkflow || lastOrder === pSet.get('order')) {
        // hybrid case, just add to membersArray, not yet to the fullArray
        addToMembersArray(pSet, this);
      } else {
        // new members list! add whatever is in membersArray (hybrid) to fullArray
        if (membersArray.length > 1) {
          // create a 'hybrid' wrapper around the hybrid participants.
          // we apply a style to this (see the declared Hybrid component)
          fullArray.push(
            <Hybrid key={'hybrid-' + pSet.get('id')}>
              <HybridWrapper numHybrid={membersArray.length} />
              {membersArray}
            </Hybrid>
          );
        } else {
          fullArray.push(membersArray[0]);
        }
        membersArray = [];
        addToMembersArray(pSet, this);
      }

      // handle last member in list
      if (i === participantList.length - 1) {
        if (membersArray.length > 1 && !parallelWorkflow) {
          // unique order! add whatever is in membersArray (hybrid) to fullArray
          // we have a hybrid participant set, create a 'hybrid' wrapper around the hybrid participants.
          fullArray.push(
            <Hybrid key={'hybrid-' + pSet.get('id')} numHybrid={membersArray.length}>
              {membersArray}
            </Hybrid>
          );
          membersArray = [];
        } else {
          fullArray.push.apply(fullArray, membersArray);
        }
      }

      lastOrder = pSet.get('order');
    });

    return fullArray.length ? <List className={classNames.MEMBER_LIST}>{fullArray}</List> : null;
  }

  render() {
    // make reactive
    this.observable && this.observable.length; // eslint-disable-line

    let agreement = this.props.agreement,
      pSets = this.participantSets,
      workflow = agreement.members.getWorkflow(),
      isTerminated = this.props.isTerminated,
      isCompleted = this.props.isCompleted,
      memberCount = pSets.length,
      completedCount = !isWidget(this.props.type) && countDone(pSets),
      agrInEndState = isCompleted || isTerminated,
      participantList = [],
      expanded = this.props.expanded,
      expandable = !!(agrInEndState || (memberCount && memberCount > MAX_MEMBERS_SHOWN)),
      membersView;

    // observable to observe
    this.props.agreement.observable.status; // eslint-disable-line

    this.workflow = workflow;
    analytics.setContext({
      members: {
        count: memberCount,
        completed: isWidget ? 'n/a' : completedCount
      }
    });

    if (!expanded) {
      if (!agrInEndState) {
        // if on the main context board, show a minimized view of participants (unless hybrid, in which case, show nothing)
        participantList =
          pSets.length > MAX_MEMBERS_SHOWN ? pSets.slice(0, MAX_MEMBERS_SHOWN) : pSets;
      }
    } else {
      participantList = pSets;
    }

    membersView = this.getMembersView(participantList);

    return (
      <section workflow={workflow} className={this.props.className}>
        <MembersHeader
          {...this.props}
          participantSets={this.participantSets} // pass in filtered p Set
          expandable={expandable}
        />
        {membersView}
      </section>
    );
  }
}

Members.propTypes = {
  // late-bind as Backbone hasn't been loaded yet
  /*
  participantSets: (props, propName, componentName) => {
    if (!(props[propName] instanceof window.Backbone.Collection)) {
      return new Error(`${componentName} requires a Backbone.Collection for prop ${propName}.`);
    }
  }, */
  type: PropTypes.string
};

export { MembersHeader };
export default withErrorBoundary(WithToastMessage(Members));
